import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
  ElementRef,
  SecurityContext,
} from '@angular/core';
import { Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalTemplateComponent } from '../../components/modal-template/modal-template.component';
import { UtilService } from '../../service/util.service';
import { ClipboardService } from 'ngx-clipboard';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { CouponService } from 'src/shared/coupon.service';
import { filter, Subject, takeUntil, take } from 'rxjs';
import 'prevent-pull-refresh';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { ICouponDetail, IFetchRedeemCoupon } from 'src/app/model/coupon-model.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { ModalService } from 'src/app/service/modal.service';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { PageRoute } from 'src/app/configs/route-config';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { ResponseCode, modalWording } from 'src/app/configs/app-config';
import { DomSanitizer, Title } from "@angular/platform-browser";
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { CouponEventTagAction } from 'src/app/model/coupon-ga.model';
import { CouponChannel, CouponStatus, MyCouponTab } from 'src/app/model/coupon-enum.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { EventAction, FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { Action } from 'rxjs/internal/scheduler/Action';
import { handleErrorCouponStatus } from 'src/app/helpers/coupon.helper';

declare let window: any;
declare var gtag: Function;
@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
  host: {
    '[class.card]': `true`,
    '[class.text-center]': `true`,
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrcodeComponent implements OnInit {
  @ViewChild('toastPopup') toastPopup: any;
  @ViewChild('navigateDeeplinkExternalUrl') navigateDeeplinkExternalUrl: ElementRef<any>;
  faTimes = faTimes; // close icon
  couponDetail: ICouponDetail | undefined;
  redeemDetail!: IFetchRedeemCoupon;
  disabledScan: boolean = false;
  isExpire: boolean = false;
  countdownTimeInSeconds: number = 0;
  countdownTimeInSecondsLeft: number = 0;
  countdownTimeInSecondsToday: number = 0;
  isShowQrUsed: boolean = true;
  isAndroid: boolean = this.utilService.isMobileDevice();
  barcodeWidth: number = 2;
  externalApp: string = "";
  stepRouteConfig$ = this.couponService.stepRouteConfig$;
  stepRouteConfig: string[] = [];
  selectedCoupon$ = this.couponService.selectedCoupon$;
  couponChannel: string = this.rootStoreService.jwtSession!.couponChannel;
  withHeader: boolean = this.rootStoreService.jwtSession?.withHeader ?? true;
  callbackDestination = this.rootStoreService.jwtSession!.callbackDestination;
  selectedCoupon: SelectedCoupon | undefined;
  CouponStatus = CouponStatus;
  tabName: string = 'QR Code';
  // barcode
  elementType = 'svg';
  value = 'PAOTANG'; //this.couponCode;
  format = 'CODE128';
  lineColor = '#000000';
  width = 1.5;
  height = 100;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  couponId: string = '';

  get values(): string[] {
    return this.value.split('\n');
  }
  codeList: string[] = [
    '',
    'CODE128',
    'CODE128A',
    'CODE128B',
    'CODE128C',
    'UPC',
    'EAN8',
    'EAN5',
    'EAN2',
    'CODE39',
    'ITF14',
    'MSI',
    'MSI10',
    'MSI11',
    'MSI1010',
    'MSI1110',
    'pharmacode',
    'codabar',
  ];

  private _destroy = new Subject<void>();

  constructor(
    private router: Router,
    private utilService: UtilService,
    private _clipboardService: ClipboardService,
    private couponService: CouponService,
    private changeDetectorRef: ChangeDetectorRef,
    private rootStoreService: RootStoreService,
    private couponGAService: CouponGAService,
    private modalService: ModalService,
    private renderer: Renderer2,
    private titleService: Title,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
    private sanitizer: DomSanitizer,
  ) {
    this.utilService.startLoading(PageRoute.QR_CODE);
    this.titleService.setTitle("CouponCodeScreen");
    this.tabName = localStorage.getItem('qrCodeTab') ?? 'QR Code';
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'reletive-content');

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.couponChannel = jwtSession.couponChannel;
      this.withHeader = jwtSession.withHeader ?? true;
      this.callbackDestination = jwtSession.callbackDestination;
    })

    this.couponService.savePreviousUrl(PageRoute.PATH_QR_CODE);
    this.couponService.saveCurrentPage(PageRoute.PATH_QR_CODE);
    this.couponService.saveStepRouteConfig([PageRoute.PATH_QR_CODE]);
    this.couponService.selectedCoupon$.pipe(filter((v) => Boolean(v)), take(1)).subscribe((result: SelectedCoupon) => {
      this.selectedCoupon = result;
    })

    if (isEmpty(this.selectedCoupon?.couponCode)) {

      this.couponService.loadFetchRedeemCoupon();
      this.couponService.redeemCoupon$
        .pipe(filter((v) => Boolean(v.result)), takeUntil(this._destroy))
        .subscribe((response) => {
          const arrstatus = [ResponseCode.ERROR_OUT_OF_QUOTA, ResponseCode.ERROR_COUPON_EXPIRED, ResponseCode.ERROR_COUPON_NOTFOUND, ResponseCode.ERROR_NOT_ELIGIBLE]
          if (arrstatus.includes(response.result.code)) {
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_OUT_OF_QUOTA]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: this.couponDetail?.campaignCode!
                }
              ],
              [ResponseCode.ERROR_COUPON_EXPIRED]: this.couponChannel === CouponChannel.NEXT ?
              [
                {
                  index: 4,
                  key: AnalyticFirebaseParam.INFORMATION,
                  value: this.couponDetail?.couponTitle!
                }
              ] :
              [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: this.couponDetail?.campaignCode!,
                }
              ],
              [ResponseCode.ERROR_COUPON_NOTFOUND]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  // value: this.couponDetail?.campaignCode!
                  value: ""
                }
              ],
              [ResponseCode.ERROR_NOT_ELIGIBLE]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: this.couponDetail?.campaignCode!
                }
              ],
            };
            const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.removeStepRouteConfig(response.result.code);
              this.couponService.clearRedeemCoupon();
            });
            return
          }
          this.redeemDetail = response.data;
          const selectedCoupon = this.mappingSelectedCoupon(CouponStatus.REDEEMED);
          this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_QR_CODE);
          this.couponService.clearRedeemCoupon();
        });
    } else {
      this.couponService.loadFetchCouponDetail();
    }

    this.couponService.couponDetail$
      .pipe(filter((v) => Boolean(v.data.coupon)), takeUntil(this._destroy))
      .subscribe((response) => {
        if (response.data.coupon) {

          this.couponDetail = cloneDeep(response.data.coupon);
          this.generateBarcode(this.couponDetail.couponCode);
          this.countdownTimeInSeconds = this.couponDetail.countdownTimeInSeconds;

          // same condition with coupon-detail 
          if (this.couponDetail.status == CouponStatus.REDEEM_CONFIRMED) {
            this.isExpire = true;
            this.disabledScan = true;
            this.isShowQrUsed = false;
          } else {
            // no timer: using timer is redemptionExpiredInSeconds
            if ((this.couponDetail.hasCountdownTimer === false) && this.couponDetail.status == CouponStatus.REDEEMED && this.couponDetail.redemptionExpiredInSeconds > 0) {
              this.countdownTimeInSecondsToday = this.couponDetail.redemptionExpiredInSeconds;
            }
          }
          const selectedCoupon = this.mappingSelectedCoupon(this.couponDetail.status);
          this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_QR_CODE);
          this.changeDetectorRef.detectChanges();
          this.webviewToNativeInterfaceService.trackAction(
            {
              action: AnalyticAction.VIEW,
              category: AnalyticCategory.COUPON_CODE_SCREEN,
              label: '',
              firebase_screen: AnalyticCategory.COUPON_CODE_SCREEN,
            },
            this.couponChannel === CouponChannel.NEXT ?
              [
                {
                  index: 4,
                  key: AnalyticFirebaseParam.INFORMATION,
                  value: this.couponDetail?.couponTitle!
                }
              ] :
              [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: this.couponDetail?.campaignCode!
                }
              ]
          )
        }
      });
    this.stepRouteConfig$.pipe(filter((v) => v?.length > 0), take(1)).subscribe((route) => {
      this.stepRouteConfig = route;
    });

  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.clearCouponDetail();
    this.renderer.removeClass(document.body, 'reletive-content');
  }

  removeStepRouteConfig(responseCode: string = "") {
    if (this.stepRouteConfig[this.stepRouteConfig.length - 2] == PageRoute.PATH_COUPON_DETAIL) {
      this.router.navigate([this.stepRouteConfig[this.stepRouteConfig.length - 2]], { queryParams: { couponId: this.selectedCoupon!.couponId, order: this.selectedCoupon!.order }, replaceUrl: true })
    } else if (this.stepRouteConfig[this.stepRouteConfig.length - 2] == PageRoute.PATH_MY_COUPON && responseCode === ResponseCode.ERROR_NOT_ELIGIBLE) {
      localStorage.setItem('onCheckedTab', MyCouponTab.EXPIRED_COUPON);
      this.router.navigate([this.stepRouteConfig[this.stepRouteConfig.length - 2]], { replaceUrl: true })
    } else {
      this.router.navigate([this.stepRouteConfig[this.stepRouteConfig.length - 2]], { replaceUrl: true })
    }
    this.couponService.removeStepRouteConfig(PageRoute.PATH_QR_CODE);
  }

  navigateBack() {
    localStorage.removeItem('qrCodeTab');
    const tabNameLabel = !this.couponDetail!.isShowQr ? `${AnalyticCategory.COUPON_CODE_SCREEN}_Back` : this.tabName === 'Barcode' ? `${AnalyticCategory.COUPON_CODE_SCREEN}_Barcode_Back` : `${AnalyticCategory.COUPON_CODE_SCREEN}_QRCode_Back`
    this.setGATag(tabNameLabel);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_CODE_SCREEN,
        label: tabNameLabel,
        firebase_screen: AnalyticCategory.COUPON_CODE_SCREEN,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: this.couponDetail?.campaignCode!
        }
      ],
    )
    if (this.stepRouteConfig[this.stepRouteConfig.length - 2] == PageRoute.PATH_COUPON_DETAIL) {
      this.router.navigate([this.stepRouteConfig[this.stepRouteConfig.length - 2]], { queryParams: { couponId: this.selectedCoupon!.couponId, order: this.selectedCoupon!.order }, replaceUrl: true })
    } else {
      this.router.navigate([this.stepRouteConfig[this.stepRouteConfig.length - 2]], { replaceUrl: true })
    }
    this.couponService.removeStepRouteConfig(PageRoute.PATH_QR_CODE)
  }


  navigateRedeemConfirmedBack() {
    if (this.stepRouteConfig[this.stepRouteConfig.length - 2] !== PageRoute.PATH_COUPON_DETAIL) {
      this.couponService.clearSelectedCouponValue();
    }
    this.navigateBack();
  }

  generateBarcode(bcode: any) {
    let isNumbers = /^\d+$/.test(bcode); // only number
    let isLetters = /^[a-zA-Z]+$/.test(bcode); // only letter

    /* Number Only */
    if (bcode.length <= 18 && isNumbers) {
      this.barcodeWidth = 2.0;
    }

    if (bcode.length > 19 && isNumbers) {
      this.barcodeWidth = 1.4;
    }
    /* Number Only */

    /* Letter Only*/

    if ((bcode.length > 3 && bcode.length <= 12 && isLetters)) {
      this.barcodeWidth = 1.7;
    }

    if ((bcode.length > 12 && bcode.length <= 15 && isLetters)) {
      this.barcodeWidth = 1.4;
    }

    if ((bcode.length > 15 && bcode.length <= 18 && isLetters)) {
      this.barcodeWidth = 1.2;
    }

    if ((bcode.length > 18 && bcode.length <= 22 && isLetters)) {
      this.barcodeWidth = 1.0;
    }

    if ((bcode.length > 22 && bcode.length <= 26 && isLetters)) {
      this.barcodeWidth = 0.8;
    }

    if ((bcode.length > 26 && isLetters)) {
      this.barcodeWidth = 0.7;
    }

    /* Letter Only */

    /* Mix */
    if ((bcode.length > 3 && bcode.length <= 12 && !isLetters && !isNumbers)) {
      this.barcodeWidth = 1.8;
    }

    if ((bcode.length > 12 && bcode.length <= 15 && !isLetters && !isNumbers)) {
      this.barcodeWidth = 1.5;
    }

    if ((bcode.length > 15 && bcode.length <= 18 && !isLetters && !isNumbers)) {
      this.barcodeWidth = 1.3;
    }

    if ((bcode.length > 18 && bcode.length <= 22 && !isLetters && !isNumbers)) {
      this.barcodeWidth = 1.1;
    }

    if ((bcode.length > 22 && bcode.length <= 26 && !isLetters && !isNumbers)) {
      this.barcodeWidth = 0.8;
    }

    if ((bcode.length > 26 && !isLetters && !isNumbers)) {
      this.barcodeWidth = 0.7;
    }
    /* Mix */
  }

  // Timer 1 min = 60 sec
  config: CountdownConfig = {
    //leftTime: this.countdownTimeInSeconds,
    //format: 'mm:ss'
  };

  handleEvent(e: CountdownEvent) {
    // Timeout
    if (e.action === 'done') {
      this.disabledScan = true;
      this.isShowQrUsed = false;
      this.modalService.closeAllDialog();
      const couponCode = cloneDeep(this.couponDetail!.couponCode)
      this.couponDetail!.couponCode = couponCode.slice(0, -4) + 'XXXX';
      const selectedCoupon = this.mappingSelectedCoupon(CouponStatus.REDEEM_CONFIRMED);
      this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_QR_CODE);
    }
  }

  // copy clipboard
  copyClipboard() {
    this.setGATag(`${AnalyticCategory.COUPON_CODE_SCREEN}_CopyCode`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_CODE_SCREEN,
        label: `${AnalyticCategory.COUPON_CODE_SCREEN}_CopyCode`,
        firebase_screen: AnalyticCategory.COUPON_CODE_SCREEN,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: this.couponDetail?.couponTitle!
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.couponDetail?.campaignCode!
          }
        ]
    )
    this._clipboardService.copyFromContent(this.couponDetail!.couponCode);

    this.toastPopup.nativeElement.classList.remove('is-hidden');
    this.toastPopup.nativeElement.classList.add('fade-out-text');
    setTimeout(() => {
      this.toastPopup.nativeElement.classList.add('is-hidden');
      this.toastPopup.nativeElement.classList.remove('fade-out-text');
    }, 3000);
  }

  confirmRedeem() {
    this.setGATag(`${AnalyticCategory.COUPON_CODE_SCREEN}_ConfirmUse`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_CODE_SCREEN,
        label: `${AnalyticCategory.COUPON_CODE_SCREEN}_ConfirmUse`,
        firebase_screen: AnalyticCategory.COUPON_CODE_SCREEN,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: this.couponDetail?.couponTitle!
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.couponDetail?.campaignCode!
          }
        ]
    )
    let modalObj: IModalTemplate = {
      title: modalWording.confirmUsedCoupon,
      detail: modalWording.notAvailableAfterConfirmed,
      analyticModal: {
        eventCategory: AnalyticCategory.COUPON_CONFIRM_USED,
        firebaseParam: this.couponChannel === CouponChannel.NEXT ?
          [
            {
              index: 4,
              key: AnalyticFirebaseParam.INFORMATION,
              value: this.couponDetail?.couponTitle!
            }
          ] :
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: this.couponDetail?.campaignCode!
            }
          ]
      },
      button: [
        { name: modalWording.cancel, action: '', eventLabel: AnalyticLabel.CANCEL },
        { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM },
      ],
    };
    this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
      if (result == "confirm") {
        this.confirmRedeemCoupon();
      }
    });
  }

  confirmRedeemCoupon() {
    this.disabledScan = true;
    this.isShowQrUsed = false;
    let couponCode = this.couponDetail?.couponCode;
    this.couponDetail!.couponCode = couponCode?.slice(0, -4) + 'XXXX';
    this.couponService.loadConfirmRedeemCoupon(this.couponDetail!.couponOrder!);
    const selectedCoupon = this.mappingSelectedCoupon(CouponStatus.REDEEM_CONFIRMED);
    this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_QR_CODE);
    this.changeDetectorRef.detectChanges();
  }

  private mappingSelectedCoupon(couponStatus: string) {
    const selectedCoupon: SelectedCoupon = {
      ...this.selectedCoupon!,
      couponStatus: couponStatus
    };

    return selectedCoupon;
  }

  goApp() { }

  navigateExternalURL(event: any) {
    event.preventDefault();

    this.setGATag(`${AnalyticCategory.COUPON_CODE_SCREEN}_GoToApp`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_CODE_SCREEN,
        label: `${AnalyticCategory.COUPON_CODE_SCREEN}_GoToApp`,
        firebase_screen: AnalyticCategory.COUPON_CODE_SCREEN,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: this.couponDetail?.campaignCode!
        }
      ]
    )

    const modalObj: IModalTemplate = {
      title: modalWording.confirmNavigateExternalUrl,
      detail: modalWording.exitFromAppGoToParner,
      analyticModal: {
        eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
        firebaseParam: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.couponDetail?.campaignCode!
          }
        ]
      },
      button: [
        { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
        { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
      ],
    }

    this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
      if (result == "confirm" && !this.disabledScan) {
        window.location.href = this.utilService.safeURL(this.couponDetail!.externalUrlDestination);
      }
    });
  }

  setGATag(eventLabel: string) {
    const gaObj: CouponEventTagAction = {
      eventName: AnalyticCategory.COUPON_CODE_SCREEN,
      eventCategory: AnalyticCategory.COUPON_CODE_SCREEN,
      eventLabel: eventLabel,
      eventAction: AnalyticAction.CLICK,
      campaignCode: this.couponDetail?.campaignCode,
      couponChannel: this.couponChannel ?? '',
    }
    this.couponGAService.trackActionGATag(gaObj)
  }

  checkValue(value: string) {
    localStorage.setItem('qrCodeTab', value);
    this.tabName = value;
    switch (this.tabName) {
      case 'QR Code': {
        this.setGATag(`${AnalyticCategory.COUPON_CODE_SCREEN}_QRCode`);
        this.webviewToNativeInterfaceService.trackAction(
          {
            action: AnalyticAction.CLICK,
            category: AnalyticCategory.COUPON_CODE_SCREEN,
            label: `${AnalyticCategory.COUPON_CODE_SCREEN}_QRCode`,
            firebase_screen: AnalyticCategory.COUPON_CODE_SCREEN,
          },
          this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 4,
                key: AnalyticFirebaseParam.INFORMATION,
                value: this.couponDetail?.couponTitle!
              }
            ] :
            [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ]
        )
        break;
      }
      case 'Barcode': {
        this.setGATag(`${AnalyticCategory.COUPON_CODE_SCREEN}_Barcode`);
        this.webviewToNativeInterfaceService.trackAction(
          {
            action: AnalyticAction.CLICK,
            category: AnalyticCategory.COUPON_CODE_SCREEN,
            label: `${AnalyticCategory.COUPON_CODE_SCREEN}_Barcode`,
            firebase_screen: AnalyticCategory.COUPON_CODE_SCREEN,
          },
          this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 4,
                key: AnalyticFirebaseParam.INFORMATION,
                value: this.couponDetail?.couponTitle!
              }
            ] :
            [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ]
        )
        break;
      }
    }
  }
}
