<div *ngIf="!(isLoading$ | async)" class="bg-blue">
    <div
        [class]="(isAndroid ? ' android-device' : '') + (isAndroid && couponChannel == CouponChannel.PT ? ' pt-margin-top' : '')">

        <div [class]="'header has-text-centered' + (withHeader ? '' : ' without-header')">
            <h3>คูปองของฉัน</h3>
            <div class="has-text-left back-btn">
                <ng-container *ngIf="(stepRouteConfig$ | async)!.length > 1; else callBackMissionBaseOnNEXT">
                    <a [routerLink]="(stepRouteConfig$ | async)![(stepRouteConfig$ | async)!.length - 2]"
                        [replaceUrl]="true" (click)="removeStepRouteConfig(); setAnalyticBack()"
                        data-testid="my-coupon-back1">
                    </a>
                </ng-container>

                <ng-template #callBackMissionBaseOnNEXT>
                    <ng-container *ngIf="callbackDestination && couponChannel == CouponChannel.NEXT; else callBackUrl">
                        <a (click)="setAnalyticBack();navigateBackWithJsbridge();" data-testid="my-coupon-back3">
                        </a>
                    </ng-container>
                </ng-template>

                <ng-template #callBackUrl>
                    <a [href]="callbackUrl | safe : 'url'" (click)="setAnalyticBack()" data-testid="my-coupon-back2">
                    </a>
                </ng-template>
            </div>
        </div>
        <div id="my-coupon-content"
            [class]="'notification pd-content' + (withHeader ? '' : ' mg-CNX') + (isAndroid && (couponChannel === CouponChannel.MBP || couponChannel === CouponChannel.NEXT) ? ' other-full-height' : '')">
            <div class="overflowhidden">
                <div [class]="'container mt-12 mb-16'">
                    <div class="columns">
                        <div class="tab-wrap">
                            <input type="radio" id="tab1" name="onCheckedTab" class="tab"
                                (click)="goTop(MyCouponTab.ACTIVE_COUPON , 'ReadyToUsePage')" data-testid="active-tab"
                                [checked]="onCheckedTab === MyCouponTab.ACTIVE_COUPON">
                            <input type="radio" id="tab2" name="onCheckedTab" class="tab"
                                (click)="goTop(MyCouponTab.USED_COUPON, 'UsePage')" data-testid="used-tab"
                                [checked]="onCheckedTab === MyCouponTab.USED_COUPON">
                            <input type="radio" id="tab3" name="onCheckedTab" class="tab"
                                (click)="goTop(MyCouponTab.EXPIRED_COUPON , 'CannotUsePage')" data-testid="expired-tab"
                                [checked]="onCheckedTab === MyCouponTab.EXPIRED_COUPON">

                            <div class="label-container is-mobile box-radius">
                                <label for="tab1" class="label font-bold">พร้อมใช้</label>
                                <label for="tab2" class="label font-bold">ใช้แล้ว</label>
                                <label for="tab3" class="label font-bold">ใช้ไม่ได้</label>
                            </div>

                            <ng-container *ngIf="this.countTimeCoupon > 0 && this.countTimeCoupon < 3600">
                                <countdown hidden [config]="{ leftTime: this.countTimeCoupon }"
                                    (event)="handleEvent($event)"></countdown>
                            </ng-container>

                            <div class="content-container">
                                <div class="tab__content" id="tab-active-coupon">
                                    <div class="coupon-empty has-text-centered" [hidden]="couponList.length > 0">
                                        <div class="columns">
                                            <img class="img-border-radius" src="/assets/images/coupon_empty.png">
                                        </div>
                                        <div class="columns font-bold">
                                            ไม่มีคูปอง
                                        </div>
                                    </div>

                                    <div>
                                        <coupon-card-horizontal #couponCard [prefixId]="'active-coupon'"
                                            [couponTemplate]="couponTemplate" [coupons]="couponList"
                                            [selectedCoupon]="onCheckedTab === MyCouponTab.ACTIVE_COUPON ? (selectedCoupon$ | async) : null"
                                            [couponTab]="MyCouponTab.ACTIVE_COUPON" [couponChannel]="couponChannel"
                                            [chooseCouponCallbackUrl]="chooseCouponCallbackUrl"
                                            [paymentCallbackUrl]="paymemtCallbackUrl"
                                            [registerCallbackUrl]="registerCallbackUrl"
                                            [processingCallbackUrl]="processingCallbackUrl"
                                            (onActionNavigateToCouponDetail)="navigateToCouponDetail($event, true)"
                                            (emitOpenDialogButtom)="openOverlay($event.coupon, $event.isInChannel, MyCouponTab.ACTIVE_COUPON)"
                                            (onActionUseCoupon)="useCoupon($event.element, $event.coupon)"
                                            (onActionUseCouponCopay)='onActionUseCouponCopay($event.eventLabel, $event.campaignCode, $event.couponShortTitle)'
                                            (onActionUseCouponPaymentTopup)="onActionUseCouponPaymentTopup($event.eventLabel, $event.campaignCode, $event.couponShortTitle, $event.destination)"
                                            (onActionUseCouponExternalURL)="navigateExternalURL($event.coupon , $event.externalUrl)"
                                            (onActionSetStepRoute)="setStepRouteFlag($event)"
                                            (onActionSelectedCoupon)="onActionSelectedCoupon($event)"
                                            (onActionPermissionError)="displayPermissionError($event.campaignCode,$event.permisionCoupon, $event?.isNavigate)"
                                            (onActionSetToLoadFech)="setToLoadFech()">
                                        </coupon-card-horizontal>
                                    </div><!-- tab__content1 -->
                                </div>


                                <div class="tab__content" id="tab-used-coupon">
                                    <div class="coupon-empty has-text-centered" [hidden]="couponUsedList.length > 0">
                                        <div class="columns">
                                            <img class="img-border-radius" src="/assets/images/coupon_empty.png">
                                        </div>
                                        <div class="columns font-bold">
                                            ไม่มีคูปอง
                                        </div>
                                    </div>

                                    <div>
                                        <coupon-card-horizontal #couponUsedCard [prefixId]="'used-coupon'"
                                            [couponTemplate]="couponTemplate" [coupons]="couponUsedList"
                                            [selectedCoupon]="onCheckedTab === MyCouponTab.USED_COUPON ? (selectedCoupon$ | async) : null"
                                            [couponChannel]="couponChannel" [couponTab]="MyCouponTab.USED_COUPON"
                                            (onActionNavigateToCouponDetail)="navigateToCouponDetail($event, true)"
                                            (emitOpenDialogButtom)="openOverlay($event.coupon, $event.isInChannel, MyCouponTab.USED_COUPON)"
                                            (onActionUseCoupon)="useCoupon($event.element, $event.coupon)">
                                        </coupon-card-horizontal>
                                    </div><!-- tab__content2 -->
                                </div>


                                <div class="tab__content" id="tab-expired-coupon">
                                    <div class="coupon-empty has-text-centered" [hidden]="couponExpiredList.length > 0">
                                        <div class="columns">
                                            <img class="img-border-radius" src="/assets/images/coupon_empty.png">
                                        </div>
                                        <div class="columns font-bold">
                                            ไม่มีคูปอง
                                        </div>
                                    </div>

                                    <div>
                                        <coupon-card-horizontal #couponExpiredCard [prefixId]="'expired-coupon'"
                                            [couponTemplate]="couponTemplate" [coupons]="couponExpiredList"
                                            [selectedCoupon]="onCheckedTab === MyCouponTab.EXPIRED_COUPON ? (selectedCoupon$ | async) : null"
                                            [couponChannel]="couponChannel" [couponTab]="MyCouponTab.EXPIRED_COUPON"
                                            (onActionNavigateToCouponDetail)="navigateToCouponDetail($event, true)"
                                            (emitOpenDialogButtom)="openOverlay($event.coupon, $event.isInChannel, MyCouponTab.EXPIRED_COUPON)">
                                        </coupon-card-horizontal>
                                    </div>
                                </div><!-- tab__content3 -->
                            </div>
                        </div><!-- tab-wrap -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>