import { NgModule, ChangeDetectionStrategy, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SwiperModule } from 'swiper/angular';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { GtagModule } from 'angular-gtag';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Store
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RootStoreModule } from './root-store/root-store.module';
import { CouponStoreModule } from './store/coupon-store.module';

// QRCode
import { QrCodeModule } from 'ng-qrcode';

// Barcode
import { NgxBarcodeModule } from 'ngx-barcode';

// Countdown for confirm redeem  ngx-countdown
import { CountdownModule } from 'ngx-countdown';
import { environment } from '../environments/environment';

// Page
import { MycouponComponent } from './pages/mycoupon/mycoupon.component';
import { HomeComponent } from './pages/home/home.component';
import { QrcodeComponent } from './pages/qrcode/qrcode.component';
import { CouponDetailComponent } from './pages/coupon-detail/coupon-detail.component';
import { UnderMaintenanceComponent } from './pages/under-maintenance/under-maintenance.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { OfflineInternetComponent } from './pages/offline-internet/offline-internet.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { SearchCouponComponent } from './pages/search-coupon/search-coupon.component';
import { CouponlistComponent } from './pages/couponlist/couponlist.component';
import { SkeletonLoaderComponent } from './pages/skeleton-loader/skeleton-loader.component';

// Component 
import { CouponCardHorizontalComponent } from './components/coupon-card-horizontal/coupon-card-horizontal.component';
import { BottomContentSliderDialogComponent } from './components/bottom-content-slider-dialog/bottom-content-slider-dialog.component';
import { CoachMarkComponent } from './components/coach-mark/coach-mark.component';
import { CouponCardHorizontalDialogComponent } from './components/coupon-card-horizontal-dialog/coupon-card-horizontal-dialog.component';
import { ModalTemplateComponent } from './components/modal-template/modal-template.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SkeletonHeaderComponent } from './components/skeleton-header/skeleton-header.component';
import { SkeletonCardComponent } from './components/skeleton-card/skeleton-card.component';
import { SkeletonDetailComponent } from './components/skeleton-detail/skeleton-detail.component';
import { SkeletonQrCodeComponent } from './components/skeleton-qr-code/skeleton-qr-code.component';
import { AnimationLoadingComponent } from './components/animation-loading/animation-loading/animation-loading.component';
import { CouponCategorySectionComponent } from './components/coupon-category-section/coupon-category-section.component';
import { FormSearchCouponComponent } from './components/form-search-coupon/form-search-coupon.component';
import { SafePipe } from './directives/safe.pipe';


@Injectable()

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MycouponComponent,
    QrcodeComponent,
    CouponDetailComponent,
    ModalTemplateComponent,
    LoadingComponent,
    UnderMaintenanceComponent,
    UnauthorizedComponent,
    OfflineInternetComponent,
    NotfoundComponent,
    CouponlistComponent,
    SafePipe,
    CouponCardHorizontalComponent,
    BottomContentSliderDialogComponent,
    CoachMarkComponent,
    CouponCardHorizontalDialogComponent,
    SkeletonLoaderComponent,
    SkeletonHeaderComponent,
    SkeletonCardComponent,
    SkeletonDetailComponent,
    SkeletonQrCodeComponent,
    AnimationLoadingComponent,
    CouponCategorySectionComponent,
    FormSearchCouponComponent,
    SearchCouponComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
    HttpClientModule,
    FontAwesomeModule,
    QrCodeModule,
    NgxBarcodeModule,
    CountdownModule,
    FormsModule,
    BrowserAnimationsModule,
    OverlayPanelModule,
    RootStoreModule,
    CouponStoreModule,
    GtagModule.forRoot({ trackingId: environment.tracking_id, trackPageviews: true }),
    NgxSkeletonLoaderModule,
    SwiperModule
  ],
  providers: [
    LoadingComponent,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
