import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { isNil } from 'lodash';
import { CountdownEvent } from 'ngx-countdown';
import { delay, filter, Subject, take } from 'rxjs';
import { modalWording, ResponseCode } from 'src/app/configs/app-config';
import { CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { checkRegisterStatus, handleErrorCouponStatus } from 'src/app/helpers/coupon.helper';
import { convertToLocaleDateString, convertToLocaleTimeString } from 'src/app/helpers/date-time.helper';
import { BadgeLabel, CopayNavigationType, CouponChannel, CouponMethod, CouponStatus, DeductionType, InternalNavigationType, MyCouponTab, PeriodStatus } from 'src/app/model/coupon-enum.model';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { ICouponInfo, ICouponModel, IMyCouponModel } from 'src/app/model/coupon-model.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { ModalService } from 'src/app/service/modal.service';
import { AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { CouponService } from 'src/shared/coupon.service';
import { ModalTemplateComponent } from '../modal-template/modal-template.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';

@Component({
  selector: 'coupon-card-horizontal',
  templateUrl: './coupon-card-horizontal.component.html',
  styleUrls: ['./coupon-card-horizontal.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, background: '#925505' }),
            animate('1s ease-in',
              style({ opacity: 0, background: '#2B77FF' }))
          ]
        )
      ]
    )
  ]

  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponCardHorizontalComponent implements OnInit {
  @ViewChildren('pickCouponCallbackUrl') pickCouponCallbackUrl: QueryList<ElementRef>;
  @ViewChildren("couponCard") couponCards: QueryList<ElementRef>;
  @ViewChild('coachMarkButton', { static: false }) coachMarkButton: ElementRef;
  @Input() prefixId: string = ''; // Use For Unit test
  @Input() couponTemplate: any;
  private _coupons: any;
  @Input()
  set coupons(v: any) {
    this._coupons = v;
  }

  public get coupons(): any {
    return this._coupons
  }

  @Input() selectedCoupon: SelectedCoupon | null;
  @Input() couponChannel: string;
  @Input() couponTab: string;
  @Input() chooseCouponCallbackUrl: string;
  @Input() paymentCallbackUrl: string;
  @Input() registerCallbackUrl: string;
  @Input() processingCallbackUrl: string;
  @Output() onActionEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionPickCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponCopay: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponPaymentTopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponExternalURL: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetStepRoute: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOpenDialogButtom: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionNavigateToCouponDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSelectedCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionPermissionError: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetToLoadFech: EventEmitter<any> = new EventEmitter<any>();
  isloadingAnimation$ = this.couponService.isLoadingAnimation$;
  CouponCardTemplate = CouponCardTemplate;
  CouponStatus = CouponStatus;
  PeriodStatus = PeriodStatus;
  BadgeLabel = BadgeLabel;
  CouponMethod = CouponMethod;

  DeductionType = DeductionType;
  MyCouponTab = MyCouponTab;
  CopayNavigationType = CopayNavigationType;
  InternalNavigationType = InternalNavigationType;
  isLoadingButton: boolean = false;
  collectedCoupon: string[] = [];
  isInternalNavi = true //mock
  isUseExternal = false //mock
  private _destroy = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private couponService: CouponService,
    private modalService: ModalService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,

  ) { }

  ngOnInit(): void {
    // console.log(this.coupons)
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.couponCollectedData$.next([]);
  }

  ngAfterViewInit() {
    if (this.couponTemplate === CouponCardTemplate.COUPON_LIST || this.couponTemplate === CouponCardTemplate.COUPON_HOME || this.couponTemplate === CouponCardTemplate.MY_COUPON) {
      this.updateContentScroll();
      this.setScrollContent();
    }
  }

  updateContentScroll() {
    this.couponCards.notifyOnChanges();
    this.changeDetectorRef.detectChanges();
  }

  setScrollContent() {
    if (this.couponCards) {
      let couponScroll = undefined;

      if (this.couponTemplate === CouponCardTemplate.COUPON_HOME) {
        const couponStatus = this.selectedCoupon?.couponStatus === CouponStatus.COLLECTED || this.selectedCoupon?.couponStatus === CouponStatus.REDEEMED ? CouponStatus.COLLECTED + CouponStatus.REDEEMED : this.selectedCoupon?.couponStatus;
        couponScroll = this.couponCards.toArray().find((element: ElementRef) => element.nativeElement.id === (`${this.selectedCoupon?.couponId}_${couponStatus}`));
      } else if (this.couponTemplate === CouponCardTemplate.MY_COUPON) {
        if (this.couponTab === MyCouponTab.ACTIVE_COUPON) {
          if (this.selectedCoupon)
            couponScroll = this.couponCards.toArray().find((element: ElementRef) => element.nativeElement.id === (`${this.selectedCoupon?.couponId}`));
        }

        if (this.couponTab === MyCouponTab.USED_COUPON) {
          if (this.selectedCoupon)
            couponScroll = this.couponCards.toArray().find((element: ElementRef) => element.nativeElement.id === (`${this.selectedCoupon?.couponId}_${this.selectedCoupon?.couponStatus}`));
        }

        if (this.couponTab === MyCouponTab.EXPIRED_COUPON) {
          if (this.selectedCoupon)
            couponScroll = this.couponCards.toArray().find((element: ElementRef) => element.nativeElement.id === (`${this.selectedCoupon?.couponId}`));
        }

      } else {
        couponScroll = this.couponCards.toArray().find((element: ElementRef) => element.nativeElement.id === (`${this.selectedCoupon?.couponId}_${this.selectedCoupon?.couponStatus}`));
      }
      couponScroll?.nativeElement?.scrollIntoView();
    }
  }

  getPrefixCouponStatus(couponInfo: ICouponInfo[]) {
    const multipleStatus = couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.COLLECTED || couponInfo.status === CouponStatus.REDEEMED || couponInfo.status === CouponStatus.NOT_ELIGIBLE_TO_USE)
    if (multipleStatus) return CouponStatus.COLLECTED + CouponStatus.REDEEMED;
    return couponInfo[0].status;
  }

  convertDateFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleDateString(date);
  }

  convertTimeFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleTimeString(date);
  }

  navigateToCouponDetail(coupon: any, index: number = 0) {

    let selectCoupon = {
      couponId: coupon.couponId,
      campaignCode: coupon.campaignCode,
      couponShortTitle: coupon.couponShortTitle,
      numberOfCoupons: coupon.numberOfCoupons,
      orders: [] as any
    };

    if (coupon.orders && coupon.orders.length > 0)
      selectCoupon.orders = [coupon.orders[index]] ?? []

    this.onActionNavigateToCouponDetail.emit(selectCoupon);
  }

  navigateFormHomepageToCouponDetail(coupon: any, index: number = 0) {

    let selectCoupon = {
      couponId: coupon.couponId,
      campaignCode: coupon.campaignCode,
      couponShortTitle: coupon.couponShortTitle,
      numberOfCoupons: coupon.numberOfCoupons,
      couponInfo: [] as any
    };

    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.couponInfo = [coupon.couponInfo[index]] ?? []

    this.onActionNavigateToCouponDetail.emit(selectCoupon);
  }

  openOverlay(coupon: any) {
    const openOverlayEmit = {
      coupon: coupon,
      isInChannel: this.isInChannel(coupon)
    };
    this.emitOpenDialogButtom.emit(openOverlayEmit);
  }

  pickCoupon(couponId: string, couponOrder: number, campaignCode: string, couponShortTitle: string, index: number = 0) {
    const cardEl = this.getCouponCard(index);
    this.couponService.isLoadingCollectCoupon$.next(true);
    this.loadAnimationCollectButton(true)
    cardEl.nativeElement.classList.remove('rotating');

    const collectedCoupon = {
      element: cardEl,
      couponId: couponId,
      couponOrder: couponOrder,
      campaignCode: campaignCode,
      couponShortTitle: couponShortTitle,
    };
    this.onActionPickCoupon.emit(collectedCoupon)
  }

  useCoupon(coupon: any, cardIndex: number, index: number = 0) {
    // this.disableActionButton(event, true);
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);

    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponShortTitle: coupon.couponShortTitle,
        orders: [] as any
      }
    }

    if (coupon.orders && coupon.orders.length > 0)
      selectCoupon.coupon.orders = [coupon.orders[index]] ?? []

    this.onActionUseCoupon.emit(selectCoupon)
  }

  useCouponForHomePage(coupon: any, cardIndex: number, index: number = 0) {
    // this.disableActionButton(event, true);
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponShortTitle: coupon.couponShortTitle,
        hasCountdownTimer: coupon.hasCountdownTimer,
        couponInfo: [] as any
      }
    }

    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.coupon.couponInfo = [coupon.couponInfo[index]] ?? []

    this.onActionUseCoupon.emit(selectCoupon)
  }

  setStepRouteFlag(couponId: string) {
    this.onActionSetStepRoute.emit(couponId)
  }

  setToLoadFech() {
    this.onActionSetToLoadFech.emit()
  }

  usePaymentTopup(coupon: any, couponOrder: number, cardIndex: number, index: number = 0, mode?: string) {
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);
    const gaObject = {
      eventLabel: 'Use',
      campaignCode: coupon.campaignCode,
      couponShortTitle: coupon.couponShortTitle,
      destination: coupon.copayNavigation.destination
    }
    this.onActionUseCouponPaymentTopup.emit(gaObject);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        orders: [] as any,
      },
      destination: coupon.copayNavigation.destination,
      compCode: coupon.copayNavigation.compCode
    }
    if (coupon.orders && coupon.orders.length > 0) {
      selectCoupon.coupon.orders = [coupon.orders[index]] ?? []
    }

    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          let permissionError = {
            campaignCode: coupon.campaignCode,
            permisionCoupon: response,
            isNavigate: true
          };
          this.onActionPermissionError.emit(permissionError);
          return;
        }

        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode,
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.setStepRouteFlag(coupon.couponId)
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
          };

          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.changeDetectorRef.detectChanges();
            this.couponService.clearReserveCouponOrder();
            this.couponService.loadFetchCoupons();
          });
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          this.disableActionCard(cardEl, false);
          this.couponService.clearReserveCouponOrder();
          //check destination
          if (coupon.copayNavigation.destination === InternalNavigationType.PT_PAYMENT || coupon.copayNavigation.destination === InternalNavigationType.PT_TOPUP) {
            this.webviewToNativeInterfaceService.interfaceToNative(
              {
                command: interfaceToNativeCommand.COMMAND_ROUTING,
                content: {
                  destination: coupon.copayNavigation.destination,
                  payeeCode: coupon.copayNavigation.compCode,
                  campaignId: coupon.couponId,
                  channel: "PT",
                }
              }
            );
          }
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode!
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
          });
        }
      });
  }

  navigateExternalURL(coupon: any, cardIndex: number, index: number = 0) {
    const cardEl = this.getCouponCard(cardIndex);
    // this.disableActionCard(cardEl, true);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponShortTitle: coupon.couponShortTitle,
        orders: [] as any,
        couponInfo: [] as any
      },
      externalUrl: coupon.copayNavigation.url
    }
    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.coupon.couponInfo = [coupon.couponInfo[index]] ?? []
    if (coupon.orders && coupon.orders.length > 0)
      selectCoupon.coupon.orders = [coupon.orders[index]] ?? []

    this.onActionUseCouponExternalURL.emit(selectCoupon)
  }

  useScanCopayCoupon(event: any, coupon: any, couponOrder: number, elementId: string, cardIndex: number, mode?: string) {
    // this.disableActionButton(event, true);
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);

    const gaObject = {
      eventLabel: 'Use',
      campaignCode: coupon.campaignCode,
      couponShortTitle: coupon.couponShortTitle
    }

    this.onActionUseCouponCopay.emit(gaObject);

    const selectCoupon = {
      couponId: coupon.couponId,
      hasCountdownTimer: coupon.hasCountdownTimer,
      orders: [] as any,
      couponInfo: [] as any
    }

    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.couponInfo = [coupon.couponInfo[0]] ?? []

    if (coupon.orders && coupon.orders.length > 0)
      selectCoupon.orders = [coupon.orders[0]] ?? []
    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          let permissionError = {
            campaignCode: coupon.campaignCode,
            permisionCoupon: response,
          };
          this.onActionPermissionError.emit(permissionError);
          return;
        }

        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {

        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {

          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.setStepRouteFlag(coupon.couponId);
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
          };
          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            const cardEl = this.getCouponCard(cardIndex);
            this.disableActionCard(cardEl, false);
            this.setToLoadFech()
          });
          return
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          this.onActionSelectedCoupon.emit(selectCoupon);
          this.couponService.clearReserveCouponOrder();

          if (this.pickCouponCallbackUrl) {
            const allCoupons = this.pickCouponCallbackUrl.toArray();
            const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
            pickCoupon!.nativeElement.click();
          }
          this.disableActionCard(cardEl, false);
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            const cardEl = this.getCouponCard(cardIndex);
            this.disableActionCard(cardEl, false);
          });
        }
      });
  }

  handleUsedTabCountdownEvent(e: CountdownEvent, index: number, order: number) {
    if (e.action === 'done') {
      const expiredCoupon = this.coupons[index];
      const expiredCouponOrder = this.coupons[index].orders[order].order
      this.coupons[index].orders[order].status = CouponStatus.REDEEM_CONFIRMED;
      const redeemConfirm = this.coupons[index].orders[order];
      const redeemCoupon = this.coupons[index].orders.filter((order: any) => order.status !== CouponStatus.REDEEM_CONFIRMED);
      if (redeemCoupon.length > 0) {
        this.coupons[index].numberOfCoupons = redeemCoupon.length;
        this.coupons[index].orders = redeemCoupon;
      } else {
        this.coupons.splice(index, 1);
      }

      const couponRedeemConfirm = this.coupons.find((coupon: any) => coupon.couponId === expiredCoupon.couponId && coupon.orders[0].status === CouponStatus.REDEEM_CONFIRMED);

      if (couponRedeemConfirm) {
        couponRedeemConfirm.orders.push(redeemConfirm);
        couponRedeemConfirm.numberOfCoupons = couponRedeemConfirm.orders.length;
      } else {
        const couponFilterRedeemed = this.coupons.filter((coupon: any) => coupon.orders[0].status === CouponStatus.REDEEMED);
        const couponFilterRedeemConfirm = this.coupons.filter((coupon: any) => coupon.orders[0].status === CouponStatus.REDEEM_CONFIRMED);
        const usedCoupon: IMyCouponModel = {
          couponId: expiredCoupon.couponId,
          campaignCode: expiredCoupon.campaignCode,
          couponName: expiredCoupon.couponName,
          couponImage: expiredCoupon.couponImage,
          couponShortTitle: expiredCoupon.couponShortTitle,
          couponSubtitle: expiredCoupon.couponSubtitle,
          badgeLabel: expiredCoupon.badgeLabel,
          couponUseMethod: expiredCoupon.couponUseMethod,
          copayChannels: expiredCoupon.copayChannels,
          isHiddenProgressBar: expiredCoupon.isHiddenProgressBar,
          numberOfCoupons: 1,
          deductionType: expiredCoupon.deductionType,
          progressionPercent: expiredCoupon.progressionPercent,
          isUseButtonDisplay: expiredCoupon.isUseButtonDisplay,
          copayNavigation: expiredCoupon.copayNavigation,
          isCoPayNavigate: expiredCoupon.isCoPayNavigate,
          orders: [redeemConfirm]
        };
        couponFilterRedeemConfirm.unshift(usedCoupon)
        this.coupons = couponFilterRedeemed.concat(couponFilterRedeemConfirm);
      }

      this.modalService.handleCouponCountdown.next({
        couponId: expiredCoupon.couponId,
        couponOrder: expiredCouponOrder
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  isInChannel(coupon: any) {
    if (coupon.copayChannels && coupon.couponUseMethod == CouponMethod.COPAY) {
      if (coupon.copayChannels[0] === CouponChannel.PT && !coupon.copayChannels.includes(this.couponChannel || '')) {
        return false
      }

      if (coupon.copayChannels[0] === CouponChannel.PW && (this.couponChannel !== CouponChannel.PT || (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate))) {
        return false
      }

      return true
    }
    return true
  }

  isDisplayBadgeLabel(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayProgressBarLabel(status: any) {
    const arrStatus = [CouponStatus.REDEEM_CONFIRMED, CouponStatus.EXPIRED, CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA, CouponStatus.OUT_OF_USED_QUOTA, CouponStatus.NOT_ELIGIBLE_TO_USE];
    return !arrStatus.includes(status);
  }

  isDisplayButton(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayMultipleCoupons(status: string, numberCoupon: number, couponOrder: number) {
    return status === CouponStatus.NEW || status === CouponStatus.OUT_OF_QUOTA || status === CouponStatus.TEMPO_OUT_OF_QUOTA || (status === CouponStatus.OUT_OF_USED_QUOTA && numberCoupon <= 1 && couponOrder === 0);
  }

  isDisplayDisableCard(status: string, periodStatus: string, isAboveMinimumSpend: boolean) {
    return status === CouponStatus.OUT_OF_QUOTA || status === CouponStatus.TEMPO_OUT_OF_QUOTA || status === CouponStatus.OUT_OF_USED_QUOTA || (status === CouponStatus.COLLECTED && (periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.PERIOD || !isAboveMinimumSpend)) || (status === CouponStatus.NEW && (periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.PERIOD)) || status === CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  displayNumberOfQuota(couponstatus: string, repetitiveStatus: boolean, numberOfTemporaryOutOfQuotaCoupon: number, numberOfOutOfQuotaCoupon: number, maximumRepetitiveCouponPerUser: number, maximumCouponPerUser: number) {

    if (couponstatus === CouponStatus.TEMPO_OUT_OF_QUOTA) {
      return numberOfTemporaryOutOfQuotaCoupon;
    }

    if (couponstatus === CouponStatus.OUT_OF_QUOTA) {
      return numberOfOutOfQuotaCoupon;
    }

    if (repetitiveStatus) {
      return maximumRepetitiveCouponPerUser;
    }

    return maximumCouponPerUser;
  }

  getCouponCard(index: number) {
    return this.couponCards.toArray()[index];
  }

  disableActionCard(element: ElementRef<any>, value: boolean = false) {
    if (value) {
      element.nativeElement.classList.add('disabled-click-action')
    } else {
      element.nativeElement.classList.remove('disabled-click-action')
    }
  }

  loadAnimationCollectButton(value: boolean = false) {
    const wrapCouponClass = document?.querySelector(".coupon-list-card")
    const loadpageClass = document?.querySelector(".loading-page");
    if (value) {
      wrapCouponClass?.classList?.add('disabled-click-action')
      loadpageClass?.classList?.add('loading-style')
    } else {
      wrapCouponClass?.classList?.remove('disabled-click-action')
      loadpageClass?.classList?.remove('loading-style')
    }
  }

  identify(index: number, coupon: any) {
    return coupon.couponId;
  }
}

