import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { ModalService } from 'src/app/service/modal.service';
import { UtilService } from 'src/app/service/util.service';
import { PageRoute } from 'src/app/configs/route-config';
import { Title } from '@angular/platform-browser';
import { CouponEventTagAction } from 'src/app/model/coupon-ga.model';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { CouponChannel, CouponStatus, PeriodStatus } from 'src/app/model/coupon-enum.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { modalWording } from 'src/app/configs/app-config';
import { ModalTemplateComponent } from '../modal-template/modal-template.component';

declare var gtag: Function;

@Component({
  selector: 'bottom-content-slider-dialog',
  templateUrl: './bottom-content-slider-dialog.component.html',
  styleUrls: ['./bottom-content-slider-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BottomContentSliderDialogComponent implements OnInit {
  couponTemplate = CouponCardTemplate.COUPON_BOTTOM_DIALOG;
  coupons: any;
  onCheckedTab: string = localStorage.getItem('onCheckedTab') ?? "1";
  titleDialog: string = "คูปองที่พร้อมใช้";
  amount: string = "0";
  couponNumber: number = 0;
  couponChannel: string = '';
  isInChannel: boolean = false;
  isMultipleStatus: boolean = false;
  CouponStatus = CouponStatus;
  isAndroid: boolean = this.utilService?.isMobileDevice();
  private _destroy = new Subject<void>();
  private _categoryEvent = '';

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BottomContentSliderDialogComponent>,
    private modalService: ModalService,
    private utilService: UtilService,
    private couponGAService: CouponGAService,
    private titleService: Title,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService


  ) { }

  ngOnInit(): void {
    this.coupons = this.data.coupon;
    this.couponChannel = this.data.couponChannel;
    this.couponTemplate = this.data.template ?? CouponCardTemplate.COUPON_BOTTOM_DIALOG;
    this.titleDialog = this.data.titleDialog;
    this.amount = this.data.amount ?? "0";
    this.isMultipleStatus = this.data.isMultipleStatus ?? false;
    this.isInChannel = this.data.isInChannel;
    this._categoryEvent = this.data.categoryEvent;
    this.titleService.setTitle(this._categoryEvent);

    if (this.isMultipleStatus) {
      const numberOfRedeemCoupons = this.coupons.couponInfo.filter((couponInfo: any) => couponInfo.status === CouponStatus.REDEEMED).length;
      this.updateDialogNumberOfCoupons(numberOfRedeemCoupons, this.coupons.couponInfo)
    } else {
      this.couponNumber = this.coupons.numberOfCoupons ?? this.coupons.numberOfCollectedCoupons;
    }

    this.modalService.couponData.pipe(takeUntil(this._destroy))
      .subscribe((coupons) => {
        if (coupons) {
          const updateCoupon = cloneDeep(coupons);

          // Home
          if (this.coupons.couponInfo) {

            if (this.coupons.couponInfo.length === updateCoupon.couponInfo.length) {
              this.coupons = updateCoupon;
            } else {
              const newCouponInfo = this.updateMappingCouponStatusRealtime(this.coupons.couponInfo, updateCoupon.couponInfo);
              updateCoupon.couponInfo = newCouponInfo;
              this.coupons = updateCoupon;
            }

            const numberOfRedeemCoupons = updateCoupon.couponInfo.filter((couponInfo: any) => couponInfo.status === CouponStatus.REDEEMED).length ?? 0;
            this.updateDialogNumberOfCoupons(numberOfRedeemCoupons, this.coupons.couponInfo);
            this.updateDialogTitleForHomePage(numberOfRedeemCoupons, this.coupons.couponInfo);

          }

          // My Coupon and Coupon Chooser
          if (this.coupons.orders) {
            this.coupons = updateCoupon;
            this.couponNumber = this.coupons.numberOfCoupons ?? this.coupons.numberOfCollectedCoupons;
            this.titleDialog = this.modalService.getHeaderDialogButtomContnet(this.coupons.orders[0].periodStatus, this.coupons.orders[0].status as CouponStatus, this.isInChannel);
          }

        } else {
          const couponInfo = this.coupons.orders ?? this.coupons.couponInfo;
          couponInfo.map((couponInfo: any) => {
            if (couponInfo.status === CouponStatus.REDEEMED) {
              couponInfo.status = CouponStatus.REDEEM_CONFIRMED
              return
            }

            if (couponInfo.status !== CouponStatus.REDEEM_CONFIRMED) {
              couponInfo.status = CouponStatus.EXPIRED;
              return;
            }
          });
          this.titleDialog = "คูปองที่ใช้ไม่ได้";
        }
        this.cdr.detectChanges();
      });

    if (this.couponTemplate === CouponCardTemplate.COUPON_HOME_BOTTOM_DIALOG) {
      this.modalService.handleCouponCountdown.pipe(takeUntil(this._destroy), filter((expiredCoupon) => expiredCoupon.couponId === this.coupons.couponId))
        .subscribe((expiredCoupon) => {
          const couponOrderExpired = this.coupons.couponInfo.find((couponOrder: any) => couponOrder.receivedOrder === expiredCoupon.couponOrder);
          if (couponOrderExpired) couponOrderExpired.status = CouponStatus.REDEEM_CONFIRMED;
          this.couponNumber = this.coupons.couponInfo.filter((couponOrder: any) => (couponOrder.status === CouponStatus.REDEEMED) || (couponOrder.status === CouponStatus.COLLECTED))?.length ?? 0;
          this.cdr.detectChanges();
        })
    }

    if (this.couponTemplate === CouponCardTemplate.COUPON_BOTTOM_DIALOG) {
      this.modalService.handleCouponCountdown.pipe(takeUntil(this._destroy), filter((expiredCoupon) => expiredCoupon.couponId === this.coupons.couponId))
        .subscribe((expiredCoupon) => {
          const couponOrderExpired = this.coupons.orders.find((couponOrder: any) => couponOrder.order === expiredCoupon.couponOrder);
          if (couponOrderExpired) couponOrderExpired.status = CouponStatus.REDEEM_CONFIRMED;
          this.couponNumber = this.coupons.orders.filter((couponOrder: any) => couponOrder.status === CouponStatus.REDEEMED)?.length ?? 0;
          this.cdr.detectChanges();
        })
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private updateMappingCouponStatusRealtime(existingCoupon: any, updateCoupon: any) {
    const newCouponInfo: any[] = [];
    existingCoupon.map((couponInfo: any) => {
      const existingCoupon = updateCoupon.find((updateCoupon: any) => updateCoupon.receivedOrder === couponInfo.receivedOrder);

      if (existingCoupon) {
        newCouponInfo.push(existingCoupon);
      } else {
        couponInfo.status = CouponStatus.EXPIRED;
        newCouponInfo.push(couponInfo);
      }
    });

    return newCouponInfo;
  }

  private updateDialogTitleForHomePage(numberOfRedeemCoupons: number, updateCoupon: any) {
    const periodStatus = numberOfRedeemCoupons > 0 ? PeriodStatus.ALLOWED : updateCoupon[0].periodStatus;
    this.titleDialog = this.modalService.getHeaderDialogButtomContnet(periodStatus, updateCoupon[0].status as CouponStatus, this.isInChannel);
  }

  private updateDialogNumberOfCoupons(numberOfRedeemCoupons: number, updateCoupon: any) {
    if (numberOfRedeemCoupons > 0) {
      const numberOfCollectedCoupons = updateCoupon.filter((couponInfo: any) => couponInfo.status === CouponStatus.COLLECTED && couponInfo.periodStatus === PeriodStatus.ALLOWED).length;
      this.couponNumber = numberOfRedeemCoupons + numberOfCollectedCoupons;
    }
    else
      this.couponNumber = this.coupons.numberOfCoupons ?? this.coupons.numberOfCollectedCoupons; // Use latest data from API
  }

  closeDialog(value?: any): void {

    this.trackActionGATag(`${this._categoryEvent}_Back`, this.coupons.campaignCode);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_Back`,
        firebase_screen: this._categoryEvent,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: this.coupons.campaignCode,
        }
      ],
    );

    document.getElementsByClassName("dialog-slide-animate")[0]?.classList.remove("dialog-side-panel")
    document.getElementsByClassName("dialog-slide-animate")[0]?.classList.add("dialog-slide-out-panel");
    setTimeout(() => {
      this.dialogRef.close(value);
    }, 200);
  }

  navigateToCouponDetail(coupon: any) {

    this.trackActionGATag(`${this._categoryEvent}_Details`, this.coupons.campaignCode);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_Details`,
        firebase_screen: this._categoryEvent,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: this.coupons.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.coupons.campaignCode,
          }
        ],
    );

    const response = {
      coupon: coupon,
      path: PageRoute.COUPON_DETAIL
    }
    this.closeDialog(response);
  }

  navigateToUseCoupon(data: any) {
    const response = {
      coupon: data.coupon,
      actionType: data.actionType,
      path: PageRoute.QR_CODE
    }
    this.closeDialog(response);
  }

  setStepRouteFlag(event: any) {
    const response = {
      path: PageRoute.SCAN_COPAY
    }
    this.closeDialog(response);
  }

  setToCloseDialog(pageRoute: PageRoute) {
    const response = {
      path: pageRoute
    }
    this.closeDialog(response);
  }

  navigateToInternal(data: any) {
    const response = {
      coupon: data.coupon,
      path: PageRoute.INTERNAL_URL,
      destination: data.destination,
      compCode: data.compCode
    }
    this.closeDialog(response);
  }

  navigateToExternal(data: any) {
    const response = {
      coupon: data.coupon,
      path: PageRoute.EXTERNAL_URL,
      externalUrl: data.externalUrl
    }
    this.closeDialog(response);
  }

  onActionTrackGAUseCoupon() {
    this.trackActionGATag(`${this._categoryEvent}_Use`, this.coupons.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: this.coupons.copayNavigation?.destination ? `${this._categoryEvent}_Use_${this.coupons.copayNavigation?.destination}` : `${this._categoryEvent}_Use`,
        firebase_screen: this._categoryEvent,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: this.coupons.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.coupons.campaignCode,

          }
        ]
    );
  }

  private trackActionGATag(label: string, campaignCode: string = '') {
    const gaObj: CouponEventTagAction = {
      eventName: this._categoryEvent,
      eventCategory: this._categoryEvent,
      eventLabel: label,
      eventAction: AnalyticAction.CLICK,
      campaignCode: campaignCode ?? '',
      couponChannel: this.couponChannel ?? ''
    }
    this.couponGAService.trackActionGATag(gaObj)
  }
}
