import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, SecurityContext } from '@angular/core';
import { SafeUrl, Title, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/service/util.service';
import { CouponService } from 'src/shared/coupon.service';
import { filter, first, Subject, take, takeUntil } from 'rxjs';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { IMyCouponModel, IPermissionUseCouponResponse } from 'src/app/model/coupon-model.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { PageRoute } from 'src/app/configs/route-config';
import { ChangeDetectorRef } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { BottomContentSliderDialogComponent } from '../../components/bottom-content-slider-dialog/bottom-content-slider-dialog.component';
import { CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { ModalTemplateComponent } from 'src/app/components/modal-template/modal-template.component';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { ResponseCode, modalWording } from 'src/app/configs/app-config';
import { CountdownEvent } from 'ngx-countdown';
import { getPeriodTime } from 'src/app/helpers/period-time.helper';
import { calculateTimeDiff } from 'src/app/helpers/date-time.helper';
import { CouponEventTagAction } from 'src/app/model/coupon-ga.model';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { CouponActionType, CouponChannel, CouponStatus, InternalNavigationType, MyCouponTab, PeriodStatus, UseActionTypeModalTemplate } from 'src/app/model/coupon-enum.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { checkRegisterStatus, handleDisplayPermissionUse, handleErrorCouponStatus } from 'src/app/helpers/coupon.helper';
import { CouponCardHorizontalComponent } from 'src/app/components/coupon-card-horizontal/coupon-card-horizontal.component';

declare let window: any;
@Component({
  selector: 'app-mycoupon',
  templateUrl: './mycoupon.component.html',
  styleUrls: ['./mycoupon.component.scss']
})

export class MycouponComponent implements OnInit {
  couponTemplate = CouponCardTemplate.MY_COUPON
  @ViewChild("couponCard") couponCards: CouponCardHorizontalComponent;
  @ViewChild("couponUsedCard") couponUsedCard: CouponCardHorizontalComponent;
  @ViewChild("couponExpiredCard") couponExpiredCard: CouponCardHorizontalComponent;
  isAndroid: boolean = this.utilService?.isMobileDevice();
  isEmpty = isEmpty;
  couponList: IMyCouponModel[] = [];
  couponUsedList: IMyCouponModel[] = [];
  couponExpiredList: IMyCouponModel[] = [];
  content: string = '';
  callbackUrl = this.rootStoreService.jwtSession!.callbackUrl;
  registerCallbackUrl = this.rootStoreService.jwtSession!.registerCallbackUrl;
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  paymemtCallbackUrl = this.rootStoreService.jwtSession!.paymentCallbackUrl;
  couponChannel: string = this.rootStoreService.jwtSession!.couponChannel;
  withHeader: boolean = this.rootStoreService.jwtSession?.withHeader ?? true;
  callbackDestination = this.rootStoreService.jwtSession!.callbackDestination;
  safeCallbackUrl: SafeUrl = '';
  onCheckedTab: string = MyCouponTab.ACTIVE_COUPON;
  stepRouteConfig$ = this.couponService.stepRouteConfig$;
  CouponStatus = CouponStatus;
  CouponChannel = CouponChannel;
  MyCouponTab = MyCouponTab;
  isLoading$ = this.couponService.isLoading$;
  selectedCoupon$ = this.couponService.selectedCoupon$;
  countTimeCoupon = 0;
  couponIdOverlay = '';
  couponIdUsedTabOverlay = '';
  private _today: Date;
  private _destroy = new Subject<void>();
  private _categoryEvent = '';
  private _countTimeCouponsPeriod = 0;
  private _countTimeCouponsRedeem = 0;

  constructor(
    private router: Router,
    private utilService: UtilService,
    private couponService: CouponService,
    private rootStoreService: RootStoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService,
    private couponGAService: CouponGAService,
    private titleService: Title,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
    private sanitizer: DomSanitizer,
  ) {
    this.utilService.startLoading(PageRoute.MY_COUPON);
    this.onCheckedTab = localStorage.getItem('onCheckedTab') ?? MyCouponTab.ACTIVE_COUPON;
    this.setTitleScreenName();
  }

  ngOnInit(): void {

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.callbackUrl = jwtSession.callbackUrl;
      this.registerCallbackUrl = jwtSession.registerCallbackUrl;
      this.chooseCouponCallbackUrl = jwtSession.chooseCouponCallbackUrl;
      this.processingCallbackUrl = jwtSession.processingCallbackUrl;
      this.paymemtCallbackUrl = jwtSession.paymentCallbackUrl;
      this.couponChannel = jwtSession.couponChannel;
      this.withHeader = jwtSession.withHeader ?? true;
      this.callbackDestination = jwtSession.callbackDestination;
    })

    this.couponService.saveStepRouteConfig([PageRoute.PATH_MY_COUPON]);
    this.couponService.saveCurrentPage(PageRoute.PATH_MY_COUPON);

    this.couponService.isInitialised$.pipe(first((v) => v)).subscribe((v) => {
      this.couponService.loadFetchMyCoupon(true);
      this.changeDetectorRef.detectChanges();
    });

    this.couponService.myCoupons$.pipe(
      filter((v) => Boolean(v.data.activeCoupons.length > 0) || Boolean(v.data.expiredCoupons.length > 0) || Boolean(v.data.usedCoupons.length > 0)),
      takeUntil(this._destroy))
      .subscribe((response) => {
        this.couponList = response.data.activeCoupons ?? [];
        this.couponUsedList = cloneDeep(response.data.usedCoupons) ?? [];
        this.couponExpiredList = response.data.expiredCoupons ?? [];

        this._today = new Date();
        this._countTimeCouponsPeriod = this.getCouponPeriodTimeCountNearCurrentTime();
        this._countTimeCouponsRedeem = this.getCouponRedeemPeriodTimeCountNearCurrentTime();

        if (this._countTimeCouponsPeriod > 0 && this._countTimeCouponsRedeem > 0)
          this.countTimeCoupon = this._countTimeCouponsPeriod > this._countTimeCouponsRedeem ? this._countTimeCouponsRedeem : this._countTimeCouponsPeriod;
        else
          this.countTimeCoupon = this._countTimeCouponsPeriod === 0 ? this._countTimeCouponsRedeem : this._countTimeCouponsPeriod;

        if (!isEmpty(this.couponIdOverlay)) {
          const filterCouponOverlay = this.couponList.find(coupon => coupon.couponId === this.couponIdOverlay) ?? this.couponExpiredList.find(coupon => coupon.couponId === this.couponIdOverlay);

          if (filterCouponOverlay) this.modalService.couponData.next(filterCouponOverlay);
          else this.modalService.couponData.next(null);

        }

        if (!isEmpty(this.couponIdUsedTabOverlay)) {
          const filterCouponOverlay = this.couponUsedList.find(coupon => coupon.couponId === this.couponIdUsedTabOverlay);

          if (filterCouponOverlay) this.modalService.couponData.next(filterCouponOverlay);

        }

        this.changeDetectorRef.detectChanges();

      });
  }

  ngAfterViewInit() {
    this.isLoading$.pipe(filter((v) => !Boolean(v)), takeUntil(this._destroy)).subscribe((v) => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  removeStepRouteConfig() {
    localStorage.removeItem('onCheckedTab');
    this.couponService.saveSelectedCategoryIdValue('0');
    this.couponService.clearSelectedCouponValue();
    this.stepRouteConfig$.pipe(take(1)).subscribe((route) => {
      if (route.length > 1) this.couponService.removeStepRouteConfig(PageRoute.PATH_MY_COUPON);
    });
  }


  private getPeriodTimeCouponExpirationDateTime(periodCoupons: IMyCouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: IMyCouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.orders[0].expirationDate);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponstartRedemptionDateTime(periodCoupons: IMyCouponModel[]) {
    let averagePeriodNew = 0;

    periodCoupons.forEach((coupon: IMyCouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.orders[0].startRedemptionDate);
      averagePeriodNew = averagePeriodNew === 0 ? timeResult : (timeResult > averagePeriodNew ? averagePeriodNew : timeResult);
    });

    return Math.ceil(averagePeriodNew / 1000);
  }

  getCouponPeriodTimeCountNearCurrentTime() {
    const newCouponsPeriod = this.couponList.filter((coupon: IMyCouponModel) => coupon.orders[0].periodStatus === PeriodStatus.PERIOD && coupon.orders[0].status === CouponStatus.NEW);
    const averagePeriodNew = this.getPeriodTimeCouponstartRedemptionDateTime(newCouponsPeriod)
    const newCouponsAllowed = this.couponList.filter((coupon: IMyCouponModel) => coupon.orders[0].periodStatus === PeriodStatus.ALLOWED && coupon.orders[0].status === CouponStatus.NEW);
    const averageAllowedNew = this.getPeriodTimeCouponExpirationDateTime(newCouponsAllowed)

    if (averagePeriodNew > 0 && averageAllowedNew > 0)
      return (averagePeriodNew > averageAllowedNew ? averageAllowedNew : averagePeriodNew);

    return (averagePeriodNew === 0 ? averageAllowedNew : averagePeriodNew);
  }

  getCouponRedeemPeriodTimeCountNearCurrentTime() {
    const couponsRedeem = this.couponUsedList.filter((coupon: IMyCouponModel) => coupon.orders[0].hasCountdownTimer === false && coupon.orders[0].status === CouponStatus.REDEEMED);
    const averageCouponRedeem = this.getPeriodTimeCouponExpirationDateTime(couponsRedeem)

    return averageCouponRedeem;

  }

  goTop(id: string, tabName: string = '') {
    window.scrollTo(0, 0);
    localStorage.removeItem('onCheckedTab');
    localStorage.setItem('onCheckedTab', id);
    this.onCheckedTab = id;

    this.setGATag(tabName);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_${tabName}`,
        firebase_screen: this._categoryEvent,
      }
    );

    this.setTitleScreenName();
  }

  navigateBackWithJsbridge() {
    this.webviewToNativeInterfaceService.interfaceToNative(
      {
        command: interfaceToNativeCommand.COMMAND_ROUTING,
        content: {
          destination: this.callbackDestination,
        }
      }
    );
  }

  navigateToCouponDetail(coupon: IMyCouponModel, isSetGA: boolean = false, index: number = 0) {
    if (isSetGA) {
      this.setGATag('CouponDetails', coupon.campaignCode);
      this.webviewToNativeInterfaceService.trackAction(
        {
          action: AnalyticAction.CLICK,
          category: this._categoryEvent,
          label: `${this._categoryEvent}_CouponDetails`,
          firebase_screen: this._categoryEvent,
        },
        this.couponChannel === CouponChannel.NEXT ?
          [
            {
              index: 4,
              key: AnalyticFirebaseParam.INFORMATION,
              value: coupon.couponShortTitle
            }
          ] :
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!,

            }
          ]
      );
    }

    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: coupon.orders[index].couponCode ?? '',
      hasCountdownTimer: coupon.orders[index].hasCountdownTimer ?? false,
      countdownTimeInSeconds: coupon.orders[index].countdownTimeInSeconds ?? 0,
      order: coupon.orders[index].order,
      couponStatus: coupon.orders[index].status
    }

    this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_MY_COUPON);
    const queryParamsCoupon = cloneDeep(selectedCoupon);
    delete queryParamsCoupon['couponStatus'];
    this.router.navigate([PageRoute.PATH_COUPON_DETAIL], { queryParams: queryParamsCoupon, replaceUrl: true });
  }

  openOverlay(coupon: IMyCouponModel, isInChannel: boolean, tab?: string,) {
    this.setGATag('MultipleCoupons', coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_MultipleCoupons`,
        firebase_screen: this._categoryEvent,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: coupon.campaignCode,
        }
      ]
    );

    let titleDialog = '';
    let overlayCategoryEvent = '';

    if (tab === MyCouponTab.EXPIRED_COUPON) {
      titleDialog = "คูปองที่ใช้ไม่ได้";
      overlayCategoryEvent = AnalyticCategory.MY_COUPON_CANNOT_USE_MULTIPLE;
    }

    if (tab === MyCouponTab.USED_COUPON) {
      titleDialog = 'คูปองที่ใช้แล้ว';
      this.couponIdUsedTabOverlay = coupon.orders[0].hasCountdownTimer === false ? coupon.couponId : '';
      overlayCategoryEvent = AnalyticCategory.MY_COUPON_USE_MULTIPLE;
    }

    if (tab === MyCouponTab.ACTIVE_COUPON) {
      this.couponIdOverlay = coupon.couponId;
      overlayCategoryEvent = AnalyticCategory.MY_COUPON_READY_TO_USE_MULTIPLE;
      titleDialog = this.modalService.getHeaderDialogButtomContnet(coupon.orders[0].periodStatus, coupon.orders[0].status as CouponStatus, isInChannel);
    }
    const couponData = {
      coupon: cloneDeep(coupon),
      couponChannel: this.couponChannel,
      titleDialog: titleDialog,
      isInChannel: isInChannel,
      template: CouponCardTemplate.COUPON_BOTTOM_DIALOG,
      categoryEvent: overlayCategoryEvent
    }

    this.modalService.openDialogButtomContnet(BottomContentSliderDialogComponent, { data: couponData, position: { bottom: '0px' } }).pipe(take(1)).subscribe((response) => {
      this.couponIdOverlay = '';
      this.couponIdUsedTabOverlay = '';
      if (response) {
        switch (response.path) {
          case PageRoute.COUPON_DETAIL: {
            this.navigateToCouponDetail(response.coupon);
            break;
          }
          case PageRoute.QR_CODE: {
            this.useCouponFormDialogBottomContent(response.coupon, response.actionType);
            break;
          }
          case PageRoute.SCAN_COPAY: {
            this.setStepRouteFlag(coupon.couponId);
            this.selectedCoupon$.pipe(take(1)).subscribe((selectedCoupon) => localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon)));
            break;
          }
          case PageRoute.EXTERNAL_URL: {
            this.navigateExternalURL(response.coupon, response.externalUrl);
            break;
          }
          case PageRoute.MY_COUPON: {
            this.setToLoadFech();
            break;
          }
        }
      }
    });
  }

  setStepRouteFlag(couponId: string) {
    localStorage.setItem("navigateExternalPage", couponId);
    this.utilService.removeTokenSession();
    // this.couponService.removeStepRouteConfig(PageRoute.PATH_MY_COUPON);
  }

  setToLoadFech() {
    this.utilService.startLoading(PageRoute.MY_COUPON);
    this.changeDetectorRef.detectChanges();
    this.couponService.loadFetchMyCoupon();
    this.goTop(MyCouponTab.EXPIRED_COUPON, 'CannotUsePage');
  }

  calculateTimeDiff(dateSent: any) {
    const newDate = new Date(dateSent);
    return calculateTimeDiff(newDate);
  }

  navigateExternalURL(coupon: any, externalUrl: string = "", index: number = 0) {
    this.setGATag('Use', coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_Use_ExternalURL`,
        firebase_screen: this._categoryEvent,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: coupon.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode!,

          }
        ]
    );
    this.couponService.loadPermissionUseCoupon(coupon.couponId, coupon.orders[index].order, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          this.displayPermissionError(coupon.campaignCode, response, true);
          return;
        }
        const modalObj: IModalTemplate = {
          title: modalWording.confirmNavigateExternalUrl,
          detail: modalWording.exitFromAppGoToParner,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
            firebaseParam: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!
              }
            ]
          },
          button: [
            { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
            { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
          ],
        }
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            window.location.href = this.utilService.safeURL(externalUrl);
          }
        });
        this.couponService.clearPermissionUseCoupon();
      });
  }

  useCoupon(element: ElementRef<any>, coupon: IMyCouponModel, index: number = 0) {
    this.setGATag('Use', coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_Use`,
        firebase_screen: this._categoryEvent,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: coupon.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode!,

          }
        ]
    );

    const selectedCoupon: SelectedCoupon = this.mappingSelectedCoupon(coupon);

    const dateRedeemdiff = this.calculateTimeDiff(coupon.orders[index].expirationDate);

    if (dateRedeemdiff < 0 && coupon.orders[index].status !== CouponStatus.REDEEMED) {
      const modalObj: IModalTemplate = {
        title: modalWording.thisCouponExpired,
        detail: modalWording.chooseAnotherCoupon,
        analyticModal: {
          eventCategory: AnalyticCategory.COUPON_EXPIRED,
          firebaseParam: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 4,
                key: AnalyticFirebaseParam.INFORMATION,
                value: coupon.couponShortTitle
              }
            ] :
            [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!,
              }
            ]
        },
        button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: "SeeOthers" }],
      };
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.couponService.loadFetchMyCoupon();
        return;
      });

    } else {
      // Success case
      this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_MY_COUPON);
      const queryParamsCoupon = cloneDeep(selectedCoupon);
      delete queryParamsCoupon['couponStatus'];

      if (coupon.orders[index].status == CouponStatus.REDEEMED) {
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
      } else {
        this.couponService.loadPermissionUseCoupon(selectedCoupon.couponId, selectedCoupon.order!);
        this.couponService.permissionUseCoupon$
          .pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
          .subscribe((response) => {
            if (!response?.permissionUseCoupon) {
              this.displayPermissionError(coupon.campaignCode, response);
              return;
            }

            let txtDetail = "";
            if (coupon.orders[index].hasCountdownTimer && coupon.orders[index].countdownTimeInSeconds > 0) {
              let countdownTimer = (coupon.orders[index].countdownTimeInSeconds) / 60;
              txtDetail = modalWording.afterConfirmCouponHaveTime + countdownTimer + modalWording.minute;
            }

            const modalObj: IModalTemplate = {
              title: modalWording.confirmUseCoupon,
              detail: txtDetail,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_CONFIRM_USING,
                firebaseParam: this.couponChannel === CouponChannel.NEXT ?
                  [
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: coupon.orders[index].countdownTimeInSeconds > 0 ? (coupon.orders[index].countdownTimeInSeconds).toString() : '0'
                    },
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: coupon.couponShortTitle
                    }
                  ] :
                  [
                    {
                      key: AnalyticFirebaseParam.CAMPAIGN_ID,
                      value: coupon.campaignCode!,
                    }
                  ]
              },
              button: [
                { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
                { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
              ]
            }
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              element.nativeElement.classList.remove('disabled-click-action')
              this.couponService.clearPermissionUseCoupon();

              if (result == "confirm") {
                this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
              }
            });
          });
      }
    }
  }

  useCouponFormDialogBottomContent(coupon: IMyCouponModel, actionType: UseActionTypeModalTemplate, index: number = 0,) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: coupon.orders[index].couponCode ?? '',
      hasCountdownTimer: coupon.orders[index].hasCountdownTimer ?? false,
      countdownTimeInSeconds: coupon.orders[index].countdownTimeInSeconds ?? 0,
      order: coupon.orders[index].order,
      couponStatus: coupon.orders[index].status
    }

    switch (actionType) {
      case UseActionTypeModalTemplate.EXPIRED: {
        const modalObj: IModalTemplate = {
          title: modalWording.thisCouponExpired,
          detail: modalWording.chooseAnotherCoupon,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_EXPIRED,
            firebaseParam: this.couponChannel === CouponChannel.NEXT ?
              [
                {
                  index: 4,
                  key: AnalyticFirebaseParam.INFORMATION,
                  value: coupon.couponShortTitle
                }
              ] :
              [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode!,
                }
              ]
          },
          button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: "SeeOthers" }],
        };
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
          this.couponService.loadFetchMyCoupon();
          return;
        });

        break;
      }
      case UseActionTypeModalTemplate.REDEEMED: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_MY_COUPON);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
        break;
      }
      case UseActionTypeModalTemplate.SUCCESS: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_MY_COUPON);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
      }
    }
  }

  handleEvent(e: CountdownEvent) {
    // Timeout
    if (e.action === 'done') {
      this.countTimeCoupon = 0;
      this.changeDetectorRef.detectChanges();
      this.couponService.loadFetchMyCoupon();
    }
  }

  private mappingSelectedCoupon(coupon: IMyCouponModel, index = 0) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: coupon.orders[index].couponCode ?? '',
      hasCountdownTimer: coupon.orders[index].hasCountdownTimer ?? false,
      countdownTimeInSeconds: coupon.orders[index].countdownTimeInSeconds ?? 0,
      order: coupon.orders[index].order,
      couponStatus: coupon.orders[index].status
    };

    return selectedCoupon;

  }

  onActionSelectedCoupon(ev: IMyCouponModel) {
    const selectedCoupon = this.mappingSelectedCoupon(ev);
    this.setStepRouteFlag(selectedCoupon.couponId);
    localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon));
  }

  displayPermissionError(campaignCode: string, permisionCoupon: IPermissionUseCouponResponse, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode
          }
        ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.couponService.clearPermissionUseCoupon();
        this.couponService.loadFetchMyCoupon();
      });
    } else {
      if (permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND) {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchMyCoupon();
          }
        });
      } else {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_API]: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 5,
                key: AnalyticFirebaseParam.HTTP_STATUS_CODE,
                value: permisionCoupon?.status
              },
              {
                index: 6,
                key: AnalyticFirebaseParam.ERROR_CODE,
                value: permisionCoupon.result?.code
              },
              {
                index: 7,
                key: AnalyticFirebaseParam.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
                value: 'False'
              },
              {
                index: 8,
                key: AnalyticFirebaseParam.ERROR_TITLE,
                value: permisionCoupon.result?.message
              }
            ] : [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_API, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchMyCoupon();
          }
        });
      }
    }
  }

  onActionUseCouponPaymentTopup(eventLabel: string, campaignCode: string, couponShortTitle: string, destination: string) {
    this.setGATag(eventLabel, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_Use_${destination}`,
        firebase_screen: this._categoryEvent,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );
  }

  onActionUseCouponCopay(eventLabel: string, campaignCode: string, couponShortTitle: string) {
    this.setGATag(eventLabel, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_${eventLabel}`,
        firebase_screen: this._categoryEvent,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );
  }

  setAnalyticBack() {
    this.setGATag('Back');
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this._categoryEvent,
        label: `${this._categoryEvent}_Back`,
        firebase_screen: this._categoryEvent,
      }
    );
  }

  setGATag(label: string, campaignCode: string = '') {
    const eventLabel = `${this._categoryEvent}_${label}`;
    this.trackActionGATag(eventLabel, campaignCode);
  }

  private setTitleScreenName() {
    if (this.onCheckedTab === MyCouponTab.ACTIVE_COUPON) this._categoryEvent = 'MyCouponReadyToUse';
    if (this.onCheckedTab === MyCouponTab.USED_COUPON) this._categoryEvent = 'MyCouponUse';
    if (this.onCheckedTab === MyCouponTab.EXPIRED_COUPON) this._categoryEvent = 'MyCouponCannotUse';

    this.titleService.setTitle(this._categoryEvent);
  }

  private trackActionGATag(label: string, campaignCode: string = '') {
    const gaObj: CouponEventTagAction = {
      eventName: this._categoryEvent,
      eventCategory: this._categoryEvent,
      eventLabel: label,
      eventAction: 'Click',
      campaignCode: campaignCode,
      couponChannel: this.couponChannel ?? '',
    }
    this.couponGAService.trackActionGATag(gaObj)
  }

}
