import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponActionType, CouponChannel, CouponStatus, InternalNavigationType, PeriodStatus, UseActionTypeModalTemplate } from '../../model/coupon-enum.model';
import { CouponService } from '../../../shared/coupon.service';
import { UtilService } from '../../service/util.service';
import { RootStoreService } from '../../root-store/root-store.service';
import { ModalService } from '../../service/modal.service';
import { CouponGAService } from '../../service/coupon-ga.service';
import { WebviewToNativeInterfaceService } from '../../webview-to-native-interface/webview-to-native-interface.service';
import { ICouponModel, IPermissionUseCouponResponse } from '../../model/coupon-model.model';
import { CountdownEvent } from 'ngx-countdown';
import { Subject, filter, first, take, takeUntil } from 'rxjs';
import { CouponCardTemplate } from '../../configs/coupon-card-config';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { PageRoute } from '../../configs/route-config';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand } from '../../webview-to-native-interface/webview-to-native-interface-enum.model';
import { CouponEventTagAction } from '../../model/coupon-ga.model';
import { CouponCardHorizontalComponent } from '../../components/coupon-card-horizontal/coupon-card-horizontal.component';
import { getCouponRepetitiveTime, getExpiredTime, getPeriodTime } from '../../helpers/period-time.helper';
import { SelectedCoupon } from '../../root-store/root-store.model';
import { BottomContentSliderDialogComponent } from '../../components/bottom-content-slider-dialog/bottom-content-slider-dialog.component';
import { IModalTemplate } from '../../model/coupon-modal.model';
import { ResponseCode, modalWording } from '../../configs/app-config';
import { ModalTemplateComponent } from '../../components/modal-template/modal-template.component';
import { checkRegisterStatus, handleDisplayPermissionUse, handleErrorCouponStatus } from 'src/app/helpers/coupon.helper';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { calculateTimeDiff } from 'src/app/helpers/date-time.helper';
import { FormSearchCouponComponent } from 'src/app/components/form-search-coupon/form-search-coupon.component';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-search-coupon',
  templateUrl: './search-coupon.component.html',
  styleUrls: ['./search-coupon.component.scss']
})
export class SearchCouponComponent implements OnInit {
  CouponStatus = CouponStatus;
  CouponActionType = CouponActionType;
  couponSearchTemplate = CouponCardTemplate.SEARCH_COUPON;
  couponTemplate = CouponCardTemplate.COUPON_HOME;
  @ViewChild('toastPopup') toastPopup: any;
  @ViewChild("couponCard") couponCards: CouponCardHorizontalComponent;
  @ViewChild("formSearchCoupon") formSearchCoupon: FormSearchCouponComponent;
  stepRouteConfig$ = this.couponService.stepRouteConfig$;
  selectedCoupon$ = this.couponService.selectedCoupon$;
  isLoading$ = this.couponService.isLoading$;
  isLoadingCollectCoupon$ = this.couponService.isLoadingCollectCoupon$;
  searchDefaultKeyword$ = this.couponService.searchDefaultKeyword$;
  searchDefaultKeywordIndex$ = this.couponService.searchDefaultKeywordIndex$;
  isAndroid: boolean = this.utilService.isMobileDevice();
  registerCallbackUrl = this.rootStoreService.jwtSession!.registerCallbackUrl;
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  paymemtCallbackUrl = this.rootStoreService.jwtSession!.paymentCallbackUrl;
  couponChannel: string = this.rootStoreService.jwtSession!.couponChannel;
  withHeader: boolean = this.rootStoreService.jwtSession!.withHeader ?? true;
  couponSearchResult: ICouponModel[] = [];
  searchKeyword: string = '';
  countTimeCoupon = 0;
  isStartSearch = false;
  isloadingSeachCoupon = false;
  isOpenModal: boolean = false;
  couponIdOverlay = '';
  private _today: Date;
  private _countRepetitiveTime = 0;
  private _countTimePeriod = 0;
  private _countTimeAllowed = 0;
  private _countTimeCouponsRedeem = 0;
  private _countTimeOutOfQuota = 0;
  private _countTimeOutRedeemConfirmOrOfUsedQuota = 0;
  private _destroy = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private couponService: CouponService,
    private rootStoreService: RootStoreService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponGAService: CouponGAService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
    private sanitizer: DomSanitizer,
  ) {
    this.utilService.stopLoading();
  }

  ngOnInit(): void {
    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.registerCallbackUrl = jwtSession.registerCallbackUrl;
      this.chooseCouponCallbackUrl = jwtSession.chooseCouponCallbackUrl;
      this.processingCallbackUrl = jwtSession.processingCallbackUrl;
      this.paymemtCallbackUrl = jwtSession.paymentCallbackUrl;
      this.couponChannel = jwtSession.couponChannel;
      this.withHeader = jwtSession.withHeader ?? true;
    })

    this.couponService.saveStepRouteConfig([PageRoute.PATH_SEARCH_COUPON]);
    this.couponService.saveCurrentPage(PageRoute.PATH_SEARCH_COUPON);

    this.couponService.searchKeyword$.pipe(filter(v => !isEmpty(v)), take(1)).subscribe((v) => this.searchKeyword = v);

    this.couponService.isInitialised$.pipe(first((v) => v)).subscribe((v) => {
      if (!isEmpty(this.searchKeyword)) {
        this.isStartSearch = true;
        this.isloadingSeachCoupon = true;
        this.couponService.loadFetchSearchCoupon(this.searchKeyword);
        this.changeDetectorRef.detectChanges();
      }
    });

    this.couponService.couponSearchResult$
      .pipe(filter(v => Boolean(v)), takeUntil(this._destroy))
      .subscribe((response) => {

        this.couponService.isLoadingCollectCoupon$.next(false);
        this.couponCards?.loadAnimationCollectButton();
        this.couponSearchResult = cloneDeep(response!);

        this._today = new Date();
        this._countTimeCouponsRedeem = this.getCouponRedeemPeriodTimeCountNearCurrentTime();
        this._countRepetitiveTime = this.getCouponRepetitiveTime();
        this._countTimePeriod = this.getCouponPeriodTimeCountNearCurrentTime();
        this._countTimeAllowed = this.getCouponAllowedTimeCountNearCurrentTime();
        this._countTimeOutOfQuota = this.getCouponOutOfQuotaNearCurrentTime();
        this._countTimeOutRedeemConfirmOrOfUsedQuota = this.getCouponRedeemConfirmOrOutOfUsedQuotaNearCurrentTime();

        // Active Coupon
        if (this._countTimePeriod > 0 && this._countTimeAllowed > 0)
          this.countTimeCoupon = this._countTimeAllowed > this._countTimePeriod ? this._countTimePeriod : this._countTimeAllowed;
        else
          this.countTimeCoupon = this._countTimeAllowed === 0 ? this._countTimePeriod : this._countTimeAllowed;

        // Repetitive Time Coupon
        if (this._countRepetitiveTime > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countRepetitiveTime ? this._countRepetitiveTime : this.countTimeCoupon;
        }

        // Redeem Coupon
        if (this._countTimeCouponsRedeem > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countTimeCouponsRedeem ? this._countTimeCouponsRedeem : this.countTimeCoupon;
        }

        // Out of quota
        if (this._countTimeOutOfQuota > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countTimeOutOfQuota ? this._countTimeOutOfQuota : this.countTimeCoupon;
        }

        // Out of used quota
        if (this._countTimeOutRedeemConfirmOrOfUsedQuota > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countTimeOutRedeemConfirmOrOfUsedQuota ? this._countTimeOutRedeemConfirmOrOfUsedQuota : this.countTimeCoupon;
        }

        if (!isEmpty(this.couponIdOverlay)) {

          const filterCouponOverlay = this.couponSearchResult.find(coupon => coupon.couponId === this.couponIdOverlay);
          if (filterCouponOverlay) this.modalService.couponData.next(filterCouponOverlay);
          else this.modalService.couponData.next(null);
        }

        this.isloadingSeachCoupon = false;
        this.changeDetectorRef.detectChanges();

      });

    this.couponService.isLoadingCollectedSccuess$
      .pipe(filter((v) => Boolean(v)), takeUntil(this._destroy))
      .subscribe(() => {
        this.toastPopup.nativeElement.classList.remove("is-hidden");
        this.toastPopup.nativeElement.classList.add("fade-out-text");
        setTimeout(() => {
          this.toastPopup.nativeElement.classList.add("is-hidden");
          this.toastPopup.nativeElement.classList.remove("fade-out-text");
        }, 3000);
      });
  }

  ngAfterViewInit() {
    if (this.searchKeyword.length == 0) {
      const tmpElement = document.createElement('input');
      tmpElement.style.width = '0';
      tmpElement.style.height = '0';
      tmpElement.style.margin = '0';
      tmpElement.style.padding = '0';
      tmpElement.style.border = '0';
      tmpElement.style.opacity = '0';
      document.body.appendChild(tmpElement);
      tmpElement.focus({ preventScroll: true });
      setTimeout(() => {
        this.formSearchCoupon.inputSearch.nativeElement.focus({ preventScroll: true });
        document.body.removeChild(tmpElement);
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.isLoadingCollectedSccuess$.next(false);
    this.couponService.clearSearchCouponResult();
  }

  private getPeriodTimeCouponStartDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;
    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponStartDatetime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponStartRedemptionDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponStartRedemptionDatetime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponEndDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponEndDatetime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponEndRedemptionDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponEndRedemptionDateTime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getExpiredTimeCouponEndDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getExpiredTime(this._today, coupon.couponInfo[0].couponEndDatetime);

      if (timeResult === 0)
        return;

      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return averageCountTime;
  }

  private getExpiredTimeCouponEndRedemptionDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getExpiredTime(this._today, coupon.couponInfo[0].couponEndRedemptionDateTime);

      if (timeResult === 0)
        return;

      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return averageCountTime;
  }

  getCouponPeriodTimeCountNearCurrentTime() {

    const newCouponsPeriod = this.couponSearchResult.filter((coupon: ICouponModel) => coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD && coupon.couponInfo[0].status === CouponStatus.NEW);
    const averageNew = this.getPeriodTimeCouponStartDateTime(newCouponsPeriod);

    // Support case multiple status :: Collected and Redeem
    const collectedCouponsPeriod = this.couponSearchResult.filter((coupon: ICouponModel) => coupon.couponInfo.some((couponInfo) => couponInfo.periodStatus === PeriodStatus.PERIOD && couponInfo.status === CouponStatus.COLLECTED));
    const averageCollected = this.getPeriodTimeCouponStartRedemptionDateTime(collectedCouponsPeriod);

    if (averageNew > 0 && averageCollected > 0)
      return (averageNew > averageCollected ? averageCollected : averageNew);

    return (averageNew === 0 ? averageCollected : averageNew);

  }

  getCouponAllowedTimeCountNearCurrentTime() {
    const newCouponsAllowed = this.couponSearchResult.filter((coupon: ICouponModel) => coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED && coupon.couponInfo[0].status === CouponStatus.NEW);
    const averageNew = this.getPeriodTimeCouponEndDateTime(newCouponsAllowed);

    // Support case multiple :: Collected and Redeem
    const collectedCouponsAllowed = this.couponSearchResult.filter((coupon: ICouponModel) => coupon.couponInfo.some((couponInfo) => couponInfo.periodStatus === PeriodStatus.ALLOWED && couponInfo.status === CouponStatus.COLLECTED));
    const averageCollected = this.getPeriodTimeCouponEndRedemptionDateTime(collectedCouponsAllowed);

    if (averageNew > 0 && averageCollected > 0)
      return (averageNew > averageCollected ? averageCollected : averageNew);

    return (averageNew === 0 ? averageCollected : averageNew);

  }

  getCouponOutOfQuotaNearCurrentTime() {
    const couponsOutOfQuota = this.couponSearchResult.filter((coupon: ICouponModel) => coupon.couponInfo[0].status === CouponStatus.OUT_OF_QUOTA || coupon.couponInfo[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA || (coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA && coupon.couponInfo[0].receivedOrder === 0));
    const averageCouponOutOfQuota = this.getExpiredTimeCouponEndDateTime(couponsOutOfQuota);
    return averageCouponOutOfQuota;
  }

  getCouponRedeemConfirmOrOutOfUsedQuotaNearCurrentTime() {
    const couponsRedeemConfirmOrOutOfUsedQuota = this.couponSearchResult.filter((coupon: ICouponModel) => (coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA && coupon.couponInfo[0].receivedOrder > 0) || coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED);
    const averageCouponRedeemConfirmOrOutOfUsedQuota = this.getExpiredTimeCouponEndRedemptionDateTime(couponsRedeemConfirmOrOutOfUsedQuota);
    return averageCouponRedeemConfirmOrOutOfUsedQuota;
  }

  getCouponRedeemPeriodTimeCountNearCurrentTime() {
    const couponsRedeem = this.couponSearchResult.filter((coupon: ICouponModel) => coupon.couponInfo.every((couponInfo) => couponInfo.status === CouponStatus.REDEEMED) && coupon.hasCountdownTimer === false);
    const averageCouponRedeem = this.getPeriodTimeCouponEndRedemptionDateTime(couponsRedeem);
    return averageCouponRedeem;
  }

  getCouponRepetitiveTime() {
    const couponRepetitive = this.couponSearchResult.filter((coupon: ICouponModel) => coupon.repetitiveStatus && coupon.isRepetitiveResetQuota);
    const averageNew = getCouponRepetitiveTime(couponRepetitive);
    return averageNew
  }

  onLoadSearchCoupon(keyword: string) {
    if (keyword.length >= 2) {
      this.isStartSearch = true;
      this.isloadingSeachCoupon = true;
      this.searchKeyword = keyword;
      this.couponService.loadFetchSearchCoupon(keyword);
      this.couponService.clearSelectedCouponValue();
    }

  }

  handleEvent(e: CountdownEvent) {
    // Timeout
    if (e.action === 'done') {
      this.countTimeCoupon = 0;
      this.changeDetectorRef.detectChanges();
      this.couponService.loadFetchSearchCoupon(this.searchKeyword);
    }
  }

  removeStepRouteConfig() {
    this.couponService.clearSearchCoupon();
    this.couponService.saveSelectedCategoryIdValue('0');
    this.couponService.clearSelectedCouponValue();
    this.stepRouteConfig$.pipe(take(1)).subscribe((route) => {
      if (route.length > 1) this.couponService.removeStepRouteConfig(PageRoute.PATH_SEARCH_COUPON);
    });
    localStorage.removeItem("searchKeyword");
  }

  navigateToCouponDetail(coupon: ICouponModel, isSetGA: boolean = false, index: number = 0) {
    if (isSetGA) {
      this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_CouponDetails`, coupon.campaignCode);

      this.webviewToNativeInterfaceService.trackAction(
        {
          action: AnalyticAction.CLICK,
          category: AnalyticCategory.COUPON_SEARCH,
          label: `${AnalyticCategory.COUPON_SEARCH}_CouponDetails`,
          firebase_screen: AnalyticCategory.COUPON_SEARCH,
        },
        this.couponChannel === CouponChannel.NEXT ?
          [
            {
              index: 4,
              key: AnalyticFirebaseParam.INFORMATION,
              value: coupon.couponShortTitle
            }
          ] :
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!,
            }
          ]
      );
    }

    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      order: coupon.couponInfo[index].receivedOrder,
      couponStatus: coupon.couponInfo[index].status
    }
    this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_SEARCH_COUPON);
    this.router.navigate([PageRoute.PATH_COUPON_DETAIL], { queryParams: { couponId: coupon.couponId, order: coupon.couponInfo[index].receivedOrder }, replaceUrl: true });
  }

  pickCoupon(element: ElementRef<any>, couponId: string, couponOrder: number, campaignCode: string, couponShortTitle: string) {
    this.couponCards.collectedCoupon.push(couponId);
    this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_Collect`, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_SEARCH,
        label: `${AnalyticCategory.COUPON_SEARCH}_Collect`,
        firebase_screen: AnalyticCategory.COUPON_SEARCH,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );

    this.couponService.loadPermissionUseCoupon(couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response?.permissionUseCoupon) {
          this.couponService.isLoadingCollectCoupon$.next(false);
          this.displayPermissionError(campaignCode, response, CouponActionType.COLLECT);
          return;
        }

        this.couponService.loadFetchCollectCoupon(couponId, couponOrder);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.collectCoupon$
      .pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        this.couponService.clearCollectCoupon();
        this.couponCards.collectedCoupon = this.couponCards.collectedCoupon.filter((v) => v !== couponId);

        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {

          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', couponId) || "";

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ]
          };
          this.couponService.isLoadingCollectCoupon$.next(false);
          this.couponCards?.loadAnimationCollectButton();
          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);
          if (!this.isOpenModal) {
            this.isOpenModal = true;
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponCards.disableActionCard(element, false);
              this.isOpenModal = false;
              this.setStepRouteFlag(couponId);
            });
          }
          return;
        }

        if (response.result.code == ResponseCode.ERROR_OUT_OF_QUOTA || response.result.code == ResponseCode.ERROR_COUPON_EXPIRED || response.result.code == ResponseCode.ERROR_COUPON_NOTFOUND || response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) { // quota = 0 || expired || Notfound

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_OUT_OF_QUOTA]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_COUPON_EXPIRED]: this.couponChannel === CouponChannel.NEXT ?
              [
                {
                  index: 4,
                  key: AnalyticFirebaseParam.INFORMATION,
                  value: couponShortTitle
                }
              ] : [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: campaignCode,
                }
              ],
            [ResponseCode.ERROR_COUPON_NOTFOUND]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
          };
          this.couponService.isLoadingCollectCoupon$.next(false);
          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          if (!this.isOpenModal) {
            this.isOpenModal = true;
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.isOpenModal = false;
              this.couponService.loadFetchSearchCoupon(this.searchKeyword);
            });
          }
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) { // success
          this.countTimeCoupon = 0;
          this.changeDetectorRef.detectChanges();
          this.couponService.loadFetchSearchCoupon(this.searchKeyword, true);
        }
      })
  }

  useCoupon(element: ElementRef<any>, coupon: ICouponModel, index: number = 0) {
    this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_Use`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_SEARCH,
        label: `${AnalyticCategory.COUPON_SEARCH}_Use`,
        firebase_screen: AnalyticCategory.COUPON_SEARCH,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: coupon.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode!,

          }
        ]
    );

    const selectedCoupon: SelectedCoupon = this.mappingSelectedCoupon(coupon);
    const dateRedeemdiff = this.calculateTimeDiff(coupon.couponInfo[0].couponEndRedemptionDateTime);

    if (dateRedeemdiff < 0 && coupon.couponInfo[index].status !== CouponStatus.REDEEMED) {
      // Error case
      const modalObj: IModalTemplate = {
        title: modalWording.thisCouponExpired,
        detail: modalWording.chooseAnotherCoupon,
        analyticModal: {
          eventCategory: AnalyticCategory.COUPON_EXPIRED,
          firebaseParam: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 4,
                key: AnalyticFirebaseParam.INFORMATION,
                value: coupon.couponShortTitle
              }
            ] :
            [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!,
              }
            ]
        },
        button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: "SeeOthers" }],
      };

      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.couponService.loadFetchSearchCoupon(this.searchKeyword);
        return;
      });

    } else {

      // Success case
      this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_SEARCH_COUPON);
      const queryParamsCoupon = cloneDeep(selectedCoupon);
      delete queryParamsCoupon['couponStatus'];
      if (coupon.couponInfo[index].status == CouponStatus.REDEEMED) {

        this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_Code`, coupon.campaignCode);
        this.webviewToNativeInterfaceService.trackAction(
          {
            action: AnalyticAction.CLICK,
            category: AnalyticCategory.COUPON_SEARCH,
            label: `${AnalyticCategory.COUPON_SEARCH}_Code`,
            firebase_screen: AnalyticCategory.COUPON_SEARCH,
          },
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!,
            }
          ]
        );
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
      } else {
        // Check in period time
        this.couponService.loadPermissionUseCoupon(selectedCoupon.couponId, selectedCoupon.order!);
        this.couponService.permissionUseCoupon$
          .pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
          .subscribe((response) => {
            if (!response?.permissionUseCoupon) {
              this.displayPermissionError(coupon.campaignCode, response, CouponActionType.USE);
              return;
            }

            let txtDetail = "";
            if (coupon.hasCountdownTimer && coupon.couponInfo[index].countdownTimeInSeconds! > 0) {
              let countdownTimer = (coupon.couponInfo[index].countdownTimeInSeconds!) / 60;
              txtDetail = modalWording.afterConfirmCouponHaveTime + countdownTimer + modalWording.minute;
            }

            const modalObj: IModalTemplate = {
              title: modalWording.confirmUseCoupon,
              detail: txtDetail,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_CONFIRM_USING,
                firebaseParam: this.couponChannel === CouponChannel.NEXT ?
                  [
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: coupon.couponInfo[index].countdownTimeInSeconds > 0 ? (coupon.couponInfo[index].countdownTimeInSeconds).toString() : '0'
                    },
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: coupon.couponShortTitle
                    }
                  ] :
                  [
                    {
                      key: AnalyticFirebaseParam.CAMPAIGN_ID,
                      value: coupon.campaignCode!,
                    }
                  ]
              },
              button: [
                { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
                { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
              ]
            }

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              this.couponCards.disableActionCard(element);
              this.couponService.clearPermissionUseCoupon();

              if (result == "confirm") {
                this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
              }
            });
          });
      }
    }
  }

  openOverlay(coupon: ICouponModel, isInChannel: boolean) {
    this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_MultipleCoupons`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_SEARCH,
        label: `${AnalyticCategory.COUPON_SEARCH}_MultipleCoupons`,
        firebase_screen: AnalyticCategory.COUPON_SEARCH,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: coupon.campaignCode,
        }
      ]
    );

    this.couponIdOverlay = coupon.couponId;
    const filterRedeemStatus = coupon.couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.REDEEMED);
    const periodStatus = filterRedeemStatus ? PeriodStatus.ALLOWED : coupon.couponInfo[0].periodStatus;
    const titleDialog = this.modalService.getHeaderDialogButtomContnet(periodStatus, coupon.couponInfo[0].status as CouponStatus, isInChannel);

    const couponData = {
      coupon: cloneDeep(coupon),
      couponChannel: this.couponChannel,
      titleDialog: titleDialog,
      isMultipleStatus: filterRedeemStatus,
      isInChannel: isInChannel,
      template: CouponCardTemplate.COUPON_HOME_BOTTOM_DIALOG,
      categoryEvent: AnalyticCategory.COUPON_SEARCH_MULTIPLE
    }
    this.modalService.openDialogButtomContnet(BottomContentSliderDialogComponent, { data: couponData, position: { bottom: '0px' } }).pipe(take(1)).subscribe((response) => {
      this.couponIdOverlay = '';
      if (response) {
        switch (response.path) {
          case PageRoute.COUPON_DETAIL: {
            this.navigateToCouponDetail(response.coupon);
            break;
          }
          case PageRoute.QR_CODE: {
            this.useCouponFormDialogBottomContent(response.coupon, response.actionType);
            break;
          }
          case PageRoute.SCAN_COPAY: {
            this.setStepRouteFlag(coupon.couponId);
            this.selectedCoupon$.pipe(take(1)).subscribe((selectedCoupon) => {
              localStorage.setItem('searchKeyword', this.searchKeyword);
              localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon));
            });
            break;
          }
          case PageRoute.EXTERNAL_URL: {
            this.navigateExternalURL(response.coupon, response.externalUrl);
            break;
          }
          case PageRoute.HOME: {
            this.setToLoadFech();
            break;
          }
        }
      }
    });
  }

  useCouponFormDialogBottomContent(coupon: ICouponModel, actionType: UseActionTypeModalTemplate, index: number = 0,) {
    const selectedCoupon: SelectedCoupon = this.mappingSelectedCoupon(coupon);

    switch (actionType) {
      case UseActionTypeModalTemplate.EXPIRED: {
        const modalObj: IModalTemplate = {
          title: modalWording.thisCouponExpired,
          detail: modalWording.chooseAnotherCoupon,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_EXPIRED,
            firebaseParam: this.couponChannel === CouponChannel.NEXT ?
              [
                {
                  index: 4,
                  key: AnalyticFirebaseParam.INFORMATION,
                  value: coupon.couponShortTitle
                }
              ] :
              [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode!,
                }
              ]
          },
          button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: AnalyticLabel.SEE_OTHER }],
        };
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
          this.couponService.loadFetchSearchCoupon(this.searchKeyword);
          return;
        });

        break;
      }
      case UseActionTypeModalTemplate.REDEEMED: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_SEARCH_COUPON);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
        break;
      }
      case UseActionTypeModalTemplate.SUCCESS: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_SEARCH_COUPON);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
      }
    }
  }

  navigateExternalURL(coupon: any, externalUrl: string = "", index: number = 0) {

    this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_Use`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_SEARCH,
        label: `${AnalyticCategory.COUPON_SEARCH}_ExternalURL`,
        firebase_screen: AnalyticCategory.COUPON_SEARCH,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: coupon.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode!,

          }
        ]
    );

    this.couponService.loadPermissionUseCoupon(coupon.couponId, coupon.couponInfo[index].receivedOrder, true);

    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response?.permissionUseCoupon) {

          this.displayPermissionError(coupon.campaignCode, response, CouponActionType.USE, true);
          return;
        }

        const modalObj: IModalTemplate = {
          title: modalWording.confirmNavigateExternalUrl,
          detail: modalWording.exitFromAppGoToParner,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
            firebaseParam: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!
              }
            ]
          },
          button: [
            { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
            { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
          ],
        };

        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            window.location.href = this.utilService.safeURL(externalUrl);
          }
        });

        this.couponService.clearPermissionUseCoupon();
      });
  }

  setStepRouteFlag(couponId: string) {
    localStorage.setItem("navigateExternalPage", couponId);
    this.utilService.removeTokenSession();
  }

  onActionSelectedCoupon(ev: ICouponModel) {
    const selectedCoupon = this.mappingSelectedCoupon(ev);
    this.setStepRouteFlag(selectedCoupon.couponId);
    localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon));
    localStorage.setItem("searchKeyword", this.searchKeyword);
  }

  setToLoadFech() {
    this.isloadingSeachCoupon = true;
    this.couponService.loadFetchSearchCoupon(this.searchKeyword);
    this.changeDetectorRef.detectChanges();
  }

  calculateTimeDiff(dateSent: any) {
    const newDate = new Date(dateSent);
    return calculateTimeDiff(newDate);
  }

  displayPermissionError(campaignCode: string, permisionCoupon: IPermissionUseCouponResponse, couponAction: CouponActionType, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.couponService.clearPermissionUseCoupon();
        this.couponService.loadFetchSearchCoupon(this.searchKeyword);
      });
    } else {
      if (permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND) {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND, couponAction, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchSearchCoupon(this.searchKeyword);
          }
        });
      } else {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_API]: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 5,
                key: AnalyticFirebaseParam.HTTP_STATUS_CODE,
                value: permisionCoupon?.status
              },
              {
                index: 6,
                key: AnalyticFirebaseParam.ERROR_CODE,
                value: permisionCoupon.result?.code
              },
              {
                index: 7,
                key: AnalyticFirebaseParam.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
                value: 'False'
              },
              {
                index: 8,
                key: AnalyticFirebaseParam.ERROR_TITLE,
                value: permisionCoupon.result?.message
              }
            ] : [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_API, couponAction, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchSearchCoupon(this.searchKeyword);
          }
        });
      }
    }
  }

  onActionUseCouponPaymentTopup(eventLabel: string, campaignCode: string, couponShortTitle: string, destination: string) {
    this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_${eventLabel}`, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_SEARCH,
        label: `${AnalyticCategory.COUPON_SEARCH}_${eventLabel}_${destination}`,
        firebase_screen: AnalyticCategory.COUPON_SEARCH,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );
  }

  onActionUseCouponCopay(eventLabel: string, campaignCode: string, couponShortTitle: string) {
    this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_${eventLabel}`, campaignCode);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_SEARCH,
        label: `${AnalyticCategory.COUPON_SEARCH}_${eventLabel}`,
        firebase_screen: AnalyticCategory.COUPON_SEARCH,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );
  }

  setAnalyticBack() {
    this.setGATag(`${AnalyticCategory.COUPON_SEARCH}_Back`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_SEARCH,
        label: `${AnalyticCategory.COUPON_SEARCH}_Back`,
        firebase_screen: AnalyticCategory.COUPON_SEARCH,
      }
    );
  }

  setGATag(label: string, campaignCode: string = '') {
    const gaObj: CouponEventTagAction = {
      eventName: AnalyticCategory.COUPON_SEARCH,
      eventCategory: AnalyticCategory.COUPON_SEARCH,
      eventLabel: label,
      eventAction: AnalyticAction.CLICK,
      campaignCode: campaignCode,
      couponChannel: this.couponChannel ?? ''
    }

    this.couponGAService.trackActionGATag(gaObj);
  }

  handleHomePageCountdownEvent(e: CountdownEvent, index: number, order: number) {
    // Todo: Increase logic to check real-time countdown for coupon is redeem status.
    // Timeout
    if (e.action === 'done') {
      const expiredCoupon = this.couponSearchResult[index];
      const expiredCouponOrder = this.couponSearchResult[index].couponInfo[order].receivedOrder
      this.couponSearchResult[index].couponInfo[order].status = CouponStatus.REDEEM_CONFIRMED;
      const redeemConfirm = this.couponSearchResult[index].couponInfo[order];
      const redeemCoupon = this.couponSearchResult[index].couponInfo.filter((couponInfo: any) => couponInfo.status !== CouponStatus.REDEEM_CONFIRMED);
      if (redeemCoupon.length > 0) {
        this.couponSearchResult[index].numberOfCoupons = redeemCoupon.length;
        this.couponSearchResult[index].couponInfo = redeemCoupon;
      } else {
        this.couponSearchResult.splice(index, 1);
      }

      const couponRedeemConfirm = this.couponSearchResult.find((coupon: any) => coupon.couponId === expiredCoupon.couponId && coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED);
      if (couponRedeemConfirm) {
        couponRedeemConfirm.couponInfo.push(redeemConfirm);
        couponRedeemConfirm.numberOfCoupons = couponRedeemConfirm.couponInfo.length;
      } else {
        if (new Date(redeemConfirm.couponEndRedemptionDateTime).getTime() < new Date().getTime()) return;
        const couponWithoutRedeemConfirm = this.couponSearchResult.filter((coupon: any) => coupon.couponInfo[0].status !== CouponStatus.REDEEM_CONFIRMED) ?? [];
        const couponFilterRedeemConfirm = this.couponSearchResult.filter((coupon: any) => coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED) ?? [];
        const usedCoupon: ICouponModel = {
          couponId: expiredCoupon.couponId,
          campaignCode: expiredCoupon.campaignCode,
          couponName: expiredCoupon.couponName,
          couponImage: expiredCoupon.couponImage,
          couponShortTitle: expiredCoupon.couponShortTitle,
          couponSubtitle: expiredCoupon.couponSubtitle,
          badgeLabel: expiredCoupon.badgeLabel,
          couponType: expiredCoupon.couponType,
          hasCountdownTimer: expiredCoupon.hasCountdownTimer,
          isHiddenProgressBar: expiredCoupon.isHiddenProgressBar,
          couponUseMethod: expiredCoupon.couponUseMethod,
          copayChannels: expiredCoupon.copayChannels,
          numberOfCoupons: 1,
          couponRedemptionWithIn: expiredCoupon.couponRedemptionWithIn,
          deductionType: expiredCoupon.deductionType,
          progressionPercent: expiredCoupon.progressionPercent,
          maximumCouponPerUser: expiredCoupon.maximumCouponPerUser,
          repetitiveStatus: expiredCoupon.repetitiveStatus,
          maximumRepetitiveCouponPerUser: expiredCoupon.maximumRepetitiveCouponPerUser,
          numberOfOutOfQuotaCoupon: expiredCoupon.numberOfOutOfQuotaCoupon,
          numberOfTemporaryOutOfQuotaCoupon: expiredCoupon.numberOfTemporaryOutOfQuotaCoupon,
          isRepetitiveResetQuota: expiredCoupon.isRepetitiveResetQuota,
          repetitiveTime: expiredCoupon.repetitiveTime,
          isUseButtonDisplay: expiredCoupon.isUseButtonDisplay,
          sourceOfFunds: expiredCoupon.sourceOfFunds,
          categoryUUIDs: expiredCoupon.categoryUUIDs ?? [],
          tags: expiredCoupon.tags ?? [],
          copayNavigation: expiredCoupon.copayNavigation,
          isCoPayNavigate: expiredCoupon.isCoPayNavigate,
          couponInfo: [redeemConfirm]
        };
        couponFilterRedeemConfirm.unshift(usedCoupon)
        this.couponSearchResult = couponWithoutRedeemConfirm.concat(couponFilterRedeemConfirm);

      }

      this.modalService.handleCouponCountdown.next({
        couponId: expiredCoupon.couponId,
        couponOrder: expiredCouponOrder
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  private mappingSelectedCoupon(coupon: ICouponModel, index = 0) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: coupon.couponInfo[index].couponCode ?? '',
      hasCountdownTimer: coupon.hasCountdownTimer ?? false,
      countdownTimeInSeconds: coupon.couponInfo[index].countdownTimeInSeconds ?? 0,
      order: coupon.couponInfo[index].receivedOrder,
      couponStatus: coupon.couponInfo[index].status
    };

    return selectedCoupon;

  }

}
